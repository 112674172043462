import React, { useEffect, useRef, useState } from 'react'
import { ExpandOutlined } from '@ant-design/icons';
import { FaSortUp } from "react-icons/fa6";
import { IoMdContract } from "react-icons/io";
import Overlay from '../checkboxOverlay';
import CombinedGraph from './CombinedGraph';
import dayjs from 'dayjs';
import { Button, DatePicker, Select } from 'antd';
import EditOverlay from './EditOverlay';
import 'ldrs/ring'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { FiDownload, FiUpload } from "react-icons/fi";
import { TailSpin } from 'react-loader-spinner';
import { Dropdown, message } from 'antd';
import { BsThreeDots } from "react-icons/bs";
import { TbTablePlus } from "react-icons/tb";
import { MdOutlineExpandCircleDown } from "react-icons/md";
import DetailedProposal from './DetailedProposal';
import { FaSortDown } from "react-icons/fa6";
import { useBase } from '../Layout/baseContext/BaseContext';
import { CgRemoveR } from "react-icons/cg";
import { FilterOutlined, } from "@ant-design/icons/lib/icons";
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import FilterInATTPopUp from './FilterInATTPopUp';
import { PiArrowsInSimple } from "react-icons/pi";
import { TbArrowNarrowUp } from "react-icons/tb";
import GraphEditPopUp from '../commonComponents/graphComponents/GraphEditPopUp';
import ExpandCheckboxList from './ExpandCheckboxList';
import ProposalTable from './ProposalTable';
import CommonExpandedRow from './CommonExpandedRow';
import { logDOM } from '@testing-library/react';
import CommonEditableRow from './CommonEditableRow';
import { GrMultiple } from "react-icons/gr";
import { TiFlashOutline } from "react-icons/ti";
import { LuInfo } from "react-icons/lu";
import { FaRegCommentDots } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import CellRightClickMenu from './CellRightClickMenu';
import TopRightCorner from './TopRightCorner';


const { Option } = Select;


const PlanningOverview = ({
    setNext,
    callingAPI,
    setPage,
    page,
    filterString,
    locationFilters,
    productFilters,
    customerFilters,
    getData,
    getGraphData,
    columnsShown,
    setColumnShown,
    setCheckBoxString,
    graphLoading,
    tdateVar,
    startDate,
    setStartDate,
    intervalList,
    graphData,
    fields,
    setFields,
    planningData,
    tableLoading,
    additionalFields,
    uiMappingData, handleRefresh }) => {


    const {
        inATTFilterPopUp, setinATTFilterPopUp,
        filterATT, setFilterATT,
        graphEditPopUp, setGraphEditPopUp,
        getProposals,
        proposalData,
        showAverage, showSum, setShowAverage, setShowSum, multiAdjustmentData, setMultiAdjustmentData,
        bucketLength, setBucketLength,
        saveRowAdjustment, selectedAdjType, setSelectedAdjType, quickFilters, setQuickFilters,
        contextMenu, setContextMenu,
        menuRef,
        handleContextMenu,
        handleOpenMenu, checkCommentsData,
        expandCheckBoxListArray, setExpandCheckBoxListArray, submitLoading,
        checkTaskData, setCheckTaskData
    } = usePlan()


    const [checkBoxOverLay, setCheckBoxOverlay] = useState(false);
    const [overlayFlag, setOverlayFlag] = useState(false)

    const { trasnposedData, setTransposedData,
        sqty, setSqty,
        fqty, setFqty,
        dates, setDates,
        fqty_budget, setFqty_budget,
        fqty_planner, setfqty_planner,
        emptyAll,
        uncheckAll,
        flag, setflag,
        checkboxCount, setCheckboxCount,
        selectedItems, setSelectedItems,
        totalSqty, setTotalSqty,
        totalFqty, setTotalFqty,
        populateGraphDisplayData,
        graphDisplayData1, setGraphDisplayData1,
        graphDisplayData2, setGraphDisplayData2,
        graphDisplayData3, setGraphDisplayData3,
        graphDisplayData4, setGraphDisplayData4,
        graphDates, setGraphDates,
        endDate, setEndDate,
        checkIndexes, setCheckIndexes,
        setIsFetchingPlan,
        adjLoading, setAdjLoading,
        showSidePanel, setShowSidePanel,
        getSidePanelData,
    } = useBase();
    // const [trasnposedData, setTransposedData] = useState(new Map());
    const [unSortedTransposedData, setUnSortedTransposedData] = useState(new Map())
    const [detailedProposal, setDetailedProposal] = useState(false)
    const [sortedIndex, setSortedIndex] = useState(null);
    const [changes, setChanges] = useState([]);
    const [salesChanges, setSalesChanges] = useState([])
    const [eventChanges, setEventChanges] = useState([])
    const [marketChanges, setMarketChanges] = useState([])
    const [promotionChanges, setPromotionChanges] = useState([])
    const [consensusChanges, setConsensusChanges] = useState([])
    const [budgetChanges, setBudgetChanges] = useState([])
    const [newChanges, setNewChanges] = useState([])
    const [disChanges, setDisChanges] = useState([])
    const tableRef = useRef(null);
    const tempTableRef = useRef(null);
    const tdRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [allSelected, setAllSelected] = useState(true)
    const [showZeroData, setShowZeroData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(fields.item)
    const [selectedLocation, setSelectedLocation] = useState(fields.location)
    const [selectedCustomer, setSelectedCustomer] = useState(fields.category)
    const divRef = useRef(null);
    const [expandRow, setExpandRow] = useState(null);

    const [Expandchecklist, setExpandchecklist] = useState({
        "Actuals": false,
        "Budget": false,
        "Opportunities": false,
        "Open Orders": false,
        "Quotations": false,
        "Year ago": false,
        "Forecast (OC)": false,
        "Sales": false,
        "Events": false,
        "Promotion": false,
        "Consensus": false,
        "Marketing": false,
        "New Products": false,
        "Discontinued Products": false
        // "Confirmed Orders": false,
        // "Due Orders": false,
        // "Weather": false,
        // "Price": false,
        // "Inventory": false,
        // "Point of Sales": false,
        // "Sell out": false
    })

    const [expandAllRows, setExpandAllRows] = useState(false)
    const [sortString, setsortString] = useState("")
    const [showMultiCellConfigIndex, setShowMultiCellConfigIndex] = useState(null)

    function filterMap(transposeMap) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const filteredMap = new Map();

        for (const [key, value] of transposeMap.entries()) {
            let includeEntry = false;

            for (const [date, data] of Object.entries(value)) {
                const currentDate = new Date(date);

                if (currentDate >= start && currentDate <= end) {
                    if (data.FQTY !== 0 && data.FQTY !== null) {
                        includeEntry = true;
                        break;
                    }
                }
            }

            if (includeEntry) {
                filteredMap.set(key, value);
            }
        }
        return filteredMap;
    }


    const [intervalFiltered, setintervalFiltered] = useState([])


    const checkForMidDate = (item, tdateVar) => {
        if (fields['interval'] === "FYEAR" && new Date(item).getFullYear() === new Date(tdateVar).getFullYear()) {
            return true;
        }
        if (fields['interval'] === "FMONTH") {
            const itemDate = new Date(item);
            const tdate = new Date(tdateVar);
            if (itemDate.getFullYear() === tdate.getFullYear() && itemDate.getMonth() === tdate.getMonth()) {
                return true;
            }
        }
        if (fields['interval'] === "FWEEK" && item === tdateVar) {
            return true;
        }
        return false;
    };

    const generateTransposeData = () => {

        if (planningData !== null && intervalList.length !== 0 && tdateVar !== null) {
            const transposeMap = new Map();

            // Filter interval list based on startDate and endDate
            const intervalListFiltered = intervalList.filter(dateString => {
                const date = new Date(dateString[fields.interval]);
                return date >= new Date(startDate) && date <= new Date(endDate);
            });

            setintervalFiltered(intervalListFiltered);


            planningData && planningData.forEach((data, index) => {
                var key = "";

                if (additionalFields.length === 0) {
                    key = `${data[fields['item']]}=${data[fields['location']]}=${data[fields['category']]}`;
                } else {
                    for (var i = 0; i < additionalFields.length; i++) {
                        if (i > 0) {
                            key += "=";
                        }
                        key += `${data[additionalFields[i]]}`;
                    }
                }

                if (!transposeMap.has(key)) {
                    transposeMap.set(key, {});
                }

                const itemMap = transposeMap.get(key);

                intervalListFiltered.forEach((interval) => {
                    if (!itemMap[interval[fields.interval]]) {
                        itemMap[interval[fields.interval]] = {
                            "FQTY": 0,
                            "SQTY": 0,
                            "isSplit": checkForMidDate(interval[fields.interval], tdateVar),
                            "FQTY_BUDGET": 0,
                            "FQTY_USER": 0,
                            "OPEN_OQTY": 0,
                            "QQTY": 0,
                            "FQTY_SALES": 0,
                            "FQTY_CONSENSUS": 0,
                            "FQTY_MARKET": 0,
                            "FQTY_PROMOTION": 0,
                            "FQTY_EVENT": 0,
                            "FQTY_NEW": 0,
                            "FQTY_DISCONTINUED": 0,
                            "FQTY_ENGINE_OC": 0,
                            "yearAgo": 0 // Initialize yearAgo with null
                        };
                    }
                });

                itemMap[data[fields.interval]] = {
                    "FQTY": data["FQTY_ENGINE"],
                    "SQTY": data["SQTY"],
                    "isSplit": checkForMidDate(data[fields.interval], tdateVar),
                    "FQTY_BUDGET": data["FQTY_BUDGET"],
                    "FQTY_USER": data["FQTY_USER"],
                    "OPEN_OQTY": data["OPEN_OQTY"],
                    "QQTY": data["QQTY"],
                    "FQTY_SALES": data["FQTY_SALES"],
                    "FQTY_CONSENSUS": data["FQTY_CONSENSUS"],
                    "FQTY_MARKET": data["FQTY_MARKET"],
                    "FQTY_PROMOTION": data["FQTY_PROMOTION"],
                    "FQTY_EVENT": data["FQTY_EVENT"],
                    "FQTY_NEW": data["FQTY_NEW"],
                    "FQTY_DISCONTINUED": data["FQTY_DISCONTINUED"],
                    "FQTY_ENGINE_OC": data["FQTY_ENGINE_OC"],
                    "yearAgo": 0 // Initialize yearAgo with null
                };
            });
            // Remove intervals not in intervalListFiltered
            transposeMap.forEach((itemMap) => {
                Object.keys(itemMap).forEach(interval => {
                    if (!intervalListFiltered.some(filteredInterval => filteredInterval[fields.interval] === interval)) {
                        delete itemMap[interval];
                    }
                });
            });
            // Add yearAgo values

            transposeMap.forEach((itemMap, key) => {
                const sortedDates = Object.keys(itemMap).sort(); // Sort dates to process chronologically

                sortedDates.forEach((currentDate, index) => {
                    const current = itemMap[currentDate];
                    let yearAgoValue = null;

                    if (index > 0) {
                        const intervalType = fields.interval; // Use interval type
                        const currentDateObj = new Date(currentDate);
                        let yearAgoDate;

                        if (intervalType === "FMONTH") {
                            // Get exact one year ago date for FMONTH
                            yearAgoDate = new Date(currentDateObj);

                            yearAgoDate.setFullYear(yearAgoDate.getFullYear() - 1);
                        } else if (intervalType === "FWEEK") {

                            // Get the closest date for FFWEEK
                            yearAgoDate = new Date(currentDateObj);
                            yearAgoDate.setFullYear(yearAgoDate.getFullYear() - 1);

                            // Find the closest date
                            const closestDate = sortedDates.reduce((closest, date) => {
                                const dateObj = new Date(date);
                                const diffCurrent = Math.abs(yearAgoDate - dateObj); // Compare with yearAgoDate
                                const diffClosest = Math.abs(yearAgoDate - new Date(closest));
                                return diffCurrent < diffClosest ? date : closest;
                            }, sortedDates[0]);

                            yearAgoDate = new Date(closestDate);
                        }
                        const yearAgoString = yearAgoDate && yearAgoDate.toISOString().split("T")[0];


                        yearAgoValue = itemMap[yearAgoString]?.SQTY || null;
                    }

                    current.yearAgo = yearAgoValue; // Set the yearAgo value
                });
            });



            const sorted = transposeMap; // Assuming sortMapBySumAvg isn't needed here
            const mapWithoutZeroes = filterMap(sorted);
            if (showZeroData) {
                setTransposedData(sorted);
                setUnSortedTransposedData(sorted);
            } else {
                setTransposedData(mapWithoutZeroes);
                setUnSortedTransposedData(mapWithoutZeroes);
            }

            return transposeMap;
        }
        return null;
    };





    useEffect(() => {
        generateTransposeData();
    }, [showZeroData])






    const addCheckIndex = (newIndex) => {
        setCheckIndexes((prev) => {
            let newTop = 2.6; // Default value for the first top

            if (prev.length > 0) {
                // Calculate new top based on the last entry
                const lastTop = prev[prev.length - 1].top;
                newTop = lastTop + 2;
            }

            // Create the new entry
            const newEntry = {
                index: newIndex,
                top: newTop,
            };

            // Create a new array with the new entry and sort it by `index`
            const updatedArray = [...prev, newEntry].sort((a, b) => a.index - b.index);
            // Adjust the `top` values after sorting
            return updatedArray.map((item, i) => ({
                ...item,
                top: 2.6 + i * 2, // Recalculate top values after sorting
            }));
        });
    };
    const removeCheckIndex = (removeIndex) => {
        setCheckIndexes((prev) => {
            // Filter out the item with the specified index
            const updatedArray = prev.filter(item => item.index !== removeIndex);

            // Recalculate the `top` values for the remaining items
            return updatedArray.map((item, i) => {
                return {
                    ...item,
                    top: 2.6 + i * 2, // Recalculate the top value based on position
                };
            });
        });
    };

    const isIndexPresent = (searchIndex) => {
        return checkIndexes.find(item => item.index === searchIndex) !== undefined;
    };

    const getTopForIndex = (rowIndex) => {
        const foundItem = checkIndexes.find(item => item.index === rowIndex);
        return foundItem ? foundItem.top : null;
    };

    const checkedGraphData = (correspondingObject, rowIndex) => {
        addCheckIndex(rowIndex)
        setCheckboxCount((prevCheckboxCount) => prevCheckboxCount + 1);

        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        if (flag === 0) {
            setflag(1);
            correspondingObject && Object.keys(correspondingObject).forEach((dateStr, index) => {
                const date = new Date(dateStr);

                if (date >= startDateObj && date <= endDateObj) {
                    dates.push(dateStr);

                    sqty.push(correspondingObject[dateStr].SQTY);
                    fqty.push(correspondingObject[dateStr].FQTY);
                    fqty_budget.push(correspondingObject[dateStr].FQTY_BUDGET === null ? null : correspondingObject[dateStr].FQTY_BUDGET)
                    fqty_planner.push(correspondingObject[dateStr].FQTY_USER === null ? null : correspondingObject[dateStr].FQTY_USER)
                }
            });
        }
        else {
            correspondingObject && Object.keys(correspondingObject).forEach((dateStr, index) => {
                const date = new Date(dateStr);
                if (date >= startDateObj && date <= endDateObj) {
                    sqty[index] = sqty[index] + correspondingObject[dateStr].SQTY;
                    fqty[index] = fqty[index] + correspondingObject[dateStr].FQTY;
                    if (correspondingObject[dateStr].FQTY_BUDGET === null) {
                        fqty_budget[index] = null
                    }
                    else {
                        fqty_budget[index] = fqty_budget[index] + correspondingObject[dateStr].FQTY_BUDGET
                    }
                    if (correspondingObject[dateStr].FQTY_USER === null) {
                        fqty_planner[index] = null
                    }
                    else {
                        fqty_planner[index] = fqty_planner[index] + correspondingObject[dateStr].FQTY_USER
                    }
                }
            });

        }



        // setGraphDisplayData1(sqty);
        // setGraphDisplayData2(fqty);
        // setGraphDisplayData3(fqty_budget)
        // setGraphDisplayData4(fqty_planner)
        // setGraphDates(dates);


    }



    var top = 2.6






    const { onDownload } = useDownloadExcel({
        currentTableRef: tempTableRef.current,
        filename: 'Users table',
        sheet: 'Users'
    })




    useEffect(() => {

        if (tdRef.current && sortOrder === null) {
            // tdRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            scrollToOffsetCenter()
        }
    }, [planningData, trasnposedData]);


    const [columnWidths, setColumnWidths] = useState({
        col1: 180,
        col2: 180,
        col3: 180,
    });
    const [dynamicColumnWidths, setdynamicColumnWidths] = useState({});
    const scrollToOffsetCenter = () => {
        if (tdRef.current && divRef.current) {
            const container = divRef.current;

            const tdElement = tdRef.current;
            let fromLeft = 0;
            // Calculate the current offset from the left and how much to scroll
            const targetPosition = tdElement.offsetLeft; // Position of tdRef from left of container
            if (additionalFields.length === 0) {
                fromLeft = columnWidths.col1 + columnWidths.col2 + columnWidths.col3
            }
            else {
                fromLeft = Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 0)
            }

            if ((showSum && !showAverage) || (!showSum && showAverage)) {
                fromLeft += 130
            }
            else if (showAverage && showSum) {
                fromLeft += 260
            }
            const scrollPosition = targetPosition - fromLeft - 90; // We want it 630px from the left  
            // Smoothly scroll to the calculated position
            container.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    };

    // useEffect(() => {
    //     if (allSelected === true) {
    //         setSelectedItems([]);
    //     }
    // }, [allSelected])
    useEffect(() => {
        uncheckAll();
    }, [fields])


    useEffect(() => {
        if (graphData !== null && startDate !== null && endDate !== null && selectedItems.length === 0)
            // checkedGraphData();

            populateGraphDisplayData();
    }, [graphData, startDate, endDate])
    // useEffect(() => {
    //     if (intervalList.length !== 0 && endDate === null) {
    //         console.log("----------interval list",intervalList);
    //         console.log("----------startDate",startDate);
    //         setEndDate(intervalList[intervalList.length - 1][fields["interval"]])

    //     }
    // }, [intervalList])
    useEffect(() => {
        if (planningData !== null && intervalList.length !== 0 && startDate !== null && endDate !== null && tdateVar !== null) {
            setSortedIndex(-1);
            setSortOrder(null)
            generateTransposeData();
            setorderOfSortForSalesForecast("")
            // emptyAll();
            // setflag(0)
            // setCheckboxCount(-1)
            // setSelectedItems([]);

        }
    }, [planningData, intervalList, additionalFields, startDate, endDate, tdateVar])

    useEffect(() => {
        setPage(2);
    }, [fields, filterString])

    function getSummedData(selectedItems, dataMap) {
        const summedData = {};

        selectedItems.forEach((item) => {
            const itemData = dataMap.get(item);

            if (itemData) {
                for (const [date, values] of Object.entries(itemData)) {
                    if (!summedData[date]) {
                        // Initialize if date entry doesn't exist yet
                        summedData[date] = { ...values }; // Clone the first values object
                    } else {
                        // Dynamically sum all fields in values
                        for (const [key, value] of Object.entries(values)) {
                            if (typeof value === 'number') {
                                summedData[date][key] = (summedData[date][key] || 0) + value;
                            }
                        }
                    }
                }
            }
        });
        return summedData;
    }


    // Function to extract and set separate datasets
    function processAndSetGraphData(result, labels) {
        // Initialize datasets for SQTY, FQTY, FQTY_BUDGET, and FQTY_USER
        const SQTYData = [];
        const FQTYData = [];
        const FQTY_BUDGETData = [];
        const FQTY_USERData = [];

        labels.forEach((label) => {
            const entry = result[label];

            // Push values or default to 0/null
            SQTYData.push(entry?.SQTY || 0);
            FQTYData.push(entry?.FQTY || 0);
            FQTY_BUDGETData.push(entry?.FQTY_BUDGET || 0);
            FQTY_USERData.push(entry?.FQTY_USER || 0);
        });

        // Now set each dataset using the provided functions

        setGraphDisplayData1(SQTYData)
        setGraphDisplayData2(FQTYData)
        setGraphDisplayData3(FQTY_BUDGETData)
        setGraphDisplayData4(FQTY_USERData)
        setGraphDates(labels)

    }



    useEffect(() => {
        if (selectedItems.length !== 0) {
            var var_new_string = ""
            var var_item_arr = `&${fields.item}=`;
            var var_location_arr = `&${fields.location}=`;
            var var_customer_arr = `&${fields.category}=`;
            selectedItems.map((item, index) => {
                const data = item.split("=")
                if (index !== selectedItems.length - 1) {
                    var_item_arr += data[0] + ","
                    var_location_arr += data[1] + ","
                    var_customer_arr += data[2] + ","
                }
                else {
                    var_item_arr += data[0]
                    var_location_arr += data[1]
                    var_customer_arr += data[2]
                }
            })
            var_new_string += var_item_arr + "&" + var_location_arr + "&" + var_customer_arr

            setCheckBoxString(encodeURI(var_new_string))
            setAllSelected(false)

            const result = getSummedData(selectedItems, trasnposedData);
            processAndSetGraphData(result, Object.keys(result));
        }
        else {
            setCheckBoxString("")
            populateGraphDisplayData()

        }
    }
        , [selectedItems, trasnposedData])

    const onClick = ({ key }) => {
        if (key === '1') {
            setShowZeroData(false);
            setSortedIndex(-1);
            message.info('Showing data without 0s');
        } else if (key === '2') {
            setShowZeroData(true);
            setSortedIndex(-1);
            message.info('Showing data with 0s');
        }
    };
    const items = [
        {
            label: 'Show Data without 0s',
            key: '1',
        },
        {
            label: 'Show Data with 0s',
            key: '2',
        }
    ];
    const [sortOrder, setSortOrder] = useState(null)
    const [keySorting, setKeySorting] = useState(false);

    function sortData(data, index) {
        uncheckAll();
        setKeySorting(true);
        setsortString("")

        let newSortOrder;

        // If the index changes, start with descending order
        if (index !== sortedIndex) {
            newSortOrder = false; // Start with descending order for the new column
        } else {
            // Determine the new sort order based on the current state
            if (sortOrder === null) {
                newSortOrder = false; // First double-click on the same column sorts in descending order
            } else if (sortOrder === false) {
                newSortOrder = true; // Second double-click sorts in ascending order
            } else {
                setTransposedData(unSortedTransposedData); // Third double-click restores original order
                setSortOrder(false);
                setSortedIndex(-1);
                setKeySorting(false);
                return;
            }
        }

        setSortOrder(newSortOrder);
        setSortedIndex(index);

        const sortedData = new Map(
            [...data.entries()].sort((a, b) => {
                const aValue = a[0].split('=')[index];
                const bValue = b[0].split('=')[index];

                if (!isNaN(aValue) && !isNaN(bValue)) {
                    return newSortOrder ? aValue - bValue : bValue - aValue;
                } else {
                    return newSortOrder ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            })
        );

        setTransposedData(sortedData);
    }



    function sortDataByDate(date, dataMap, index, asc) {
        uncheckAll();
        setKeySorting(false)
        setsortString("")
        if (sortOrder === asc && index === sortedIndex && !keySorting) {
            setTransposedData(unSortedTransposedData)
            setSortOrder(false);
            setSortedIndex(-1)
        }
        else {
            setSortOrder(asc)
            setSortedIndex(index);
            const dataArray = Array.from(dataMap.entries());

            dataArray.sort((a, b) => {
                const aData = a[1][date];
                const bData = b[1][date];

                let comparison = 0;
                if (aData.FQTY_USER !== null && bData.FQTY_USER !== null) {
                    comparison = aData.FQTY_USER - bData.FQTY_USER;
                } else if (aData.FQTY_USER !== null) {
                    comparison = -1;
                } else if (bData.FQTY_USER !== null) {
                    comparison = 1;
                } else {
                    comparison = aData.SQTY - bData.SQTY;
                }

                return asc ? comparison : -comparison;
            });

            const sortedMap = new Map(dataArray);
            if (showZeroData) {
                setTransposedData(sortedMap)
            }
            else {
                const sortedWithoutZeroes = filterMap(sortedMap)
                setTransposedData(sortedWithoutZeroes)
            }


        }
    }





    const [cwidth, setcwidth] = useState(-1)


    const handleScroll = () => {
        if (divRef.current && !callingAPI) {
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = divRef.current;
            setcwidth(scrollLeft)
            if ((scrollTop + clientHeight >= scrollHeight - 1) && scrollLeft === cwidth) {
                setPage((prevPage) => prevPage + 1);

            }
        }
    };


    useEffect(() => {
        const divElement = divRef.current;
        if (divElement) {
            divElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    });

    var midIndex = 0;







    const col1Ref = useRef(null);
    const col2Ref = useRef(null);
    const col3Ref = useRef(null);

    const handleMouseDown = (e, colKey, colRef) => {
        const startX = e.clientX;

        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) {

                setColumnWidths((prevWidths) => {
                    // Spread the current state to a new object

                    const updatedWidths = { ...prevWidths, [colKey]: newWidth };
                    // Return the new state object
                    return updatedWidths;
                });
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };



    const columnRefs = useRef({});

    useEffect(() => {
        // Update dynamic column widths when additionalFields is updated
        const newDynamicColumnWidths = additionalFields.reduce((widths, col, index) => {
            widths[`col${index}`] = 180; // Default width of 180
            return widths;
        }, {});

        setdynamicColumnWidths(newDynamicColumnWidths);

        // Update refs when additionalFields is updated
        columnRefs.current = additionalFields.reduce((refs, col, index) => {
            refs[`col${index}`] = React.createRef();
            return refs;
        }, {});
    }, [additionalFields]);



    const handleMouseDownDynamic = (e, colKey) => {
        const colRef = columnRefs.current[colKey];
        const startX = e.clientX;
        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) { // Ensure minimum width of 50px
                setdynamicColumnWidths((prevWidths) => {
                    const updatedWidths = { ...prevWidths, [colKey]: newWidth }
                    return updatedWidths
                })
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    const checkboxRefs = useRef([]); // Array to store refs for each checkbox
    // Helper function to handle keydown event
    // const handleKeyDown = (e, index) => {
    //     const currentItem = [...trasnposedData.keys()][index];
    //     if (e.key === 'ArrowDown') {
    //         if (index < trasnposedData.size - 1) {
    //             // Uncheck the current checkbox and check the next one
    //             // uncheckGraphData(correspondingObject, index);
    //             setSelectedItems(prevState => prevState.filter(item => item !== currentItem));

    //             const nextItem = [...trasnposedData.keys()][index + 1];
    //             // checkedGraphData(nextCorrespondingObject, index + 1);
    //             setSelectedItems(prevState => [...prevState, nextItem]);

    //             // Move focus to the next checkbox
    //             checkboxRefs.current[index + 1].focus();
    //         }
    //     } else if (e.key === 'ArrowUp') {
    //         if (index > 0) {
    //             // Uncheck the current checkbox and check the previous one
    //             // uncheckGraphData(correspondingObject, index);
    //             setSelectedItems(prevState => prevState.filter(item => item !== currentItem));

    //             const prevItem = [...trasnposedData.keys()][index - 1];
    //             // checkedGraphData(prevCorrespondingObject, index - 1);
    //             setSelectedItems(prevState => [...prevState, prevItem]);

    //             // Move focus to the previous checkbox
    //             checkboxRefs.current[index - 1].focus();
    //         }
    //     }
    // };
    const handleKeyDown = (e, index) => {
        const currentItem = [...trasnposedData.keys()][index];

        if (e.key === 'ArrowDown') {
            if (index < trasnposedData.size - 1) {
                // Uncheck the current checkbox and update indices
                setSelectedItems(prevState => {
                    removeCheckIndex(index); // Remove current index from checkIndexes
                    return prevState.filter(item => item !== currentItem);
                });

                const nextItem = [...trasnposedData.keys()][index + 1];
                // Check the next checkbox and update indices
                setSelectedItems(prevState => {
                    addCheckIndex(index + 1); // Add next index to checkIndexes
                    return [...prevState, nextItem];
                });

                // Move focus to the next checkbox
                checkboxRefs.current[index + 1].focus();
            }
        } else if (e.key === 'ArrowUp') {
            if (index > 0) {
                // Uncheck the current checkbox and update indices
                setSelectedItems(prevState => {
                    removeCheckIndex(index); // Remove current index from checkIndexes
                    return prevState.filter(item => item !== currentItem);
                });

                const prevItem = [...trasnposedData.keys()][index - 1];
                // Check the previous checkbox and update indices
                setSelectedItems(prevState => {
                    addCheckIndex(index - 1); // Add previous index to checkIndexes
                    return [...prevState, prevItem];
                });

                // Move focus to the previous checkbox
                checkboxRefs.current[index - 1].focus();
            }
        }
    };



    const barData1 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: 'SQTY',
                data: graphDisplayData1 !== null ? graphDisplayData1 : [],
                backgroundColor: "#ffdb5c",
                borderWidth: 1,
            },
        ],
    };

    const barData2 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: fields["base"],
                data: graphDisplayData2 !== null ? graphDisplayData2 : [],
                backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
            },
        ],
    };
    const lineData1 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: fields.base_3,
                data: graphDisplayData3 !== null ? graphDisplayData3 : [],
                backgroundColor: "blue"
            },
        ],
    };
    const lineData2 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: fields["base_4"],
                data: graphDisplayData4 !== null ? graphDisplayData4 : [],
                backgroundColor: "red"
            },
        ],
    };


    const isDateInRange = (date, start, end) => {
        return new Date(date) >= new Date(start) && new Date(date) <= new Date(end);
    };

    // Step 2: Function to calculate sum and average of SQTY between dates
    const calculateSumAndAvg = (data, type, isSum) => {
        let sum = 0;
        let count = 0;

        if (type === 'sales') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, startDate, tdateVar)) {
                    const sqty = data[date].SQTY;
                    if (sqty !== null) {
                        sum += sqty;
                        count += 1;
                    }
                }
            });
        }

        if (type === 'forecast') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, tdateVar, endDate)) {
                    const fqty = data[date].FQTY;
                    if (fqty !== null) {
                        sum += fqty;
                        count += 1;
                    }
                }
            });
        }


        const avg = count > 0 ? sum / count : 0;

        if (isSum) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(sum);
        }
        else {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(avg);
        }
    };

    // Helper function to check if a change matches the current details and interval
    const doesChangeMatch = (change, details, interval, additionalFields) => {
        if (additionalFields.length > 0) {
            // Check all dynamic fields from additionalFields
            return additionalFields.every((field, idx) => change[field] === details[idx]) && change["interval"] === interval;
        } else {
            // Check for hardcoded fields when additionalFields is empty
            return change[selectedItem] === details[0] &&
                change[selectedLocation] === details[1] &&
                change[selectedCustomer] === details[2] &&
                change["interval"] === interval;
        }
    };

    // Helper function to create a new entry for the changes array
    const createNewEntry = (value, details, interval, additionalFields, keyValue, prevValue) => {
        let newEntry;

        const validKeys = [
            "FQTY_USER",
            "FQTY_SALES",
            "FQTY_CONSENSUS",
            "FQTY_MARKET",
            "FQTY_PROMOTION",
            "FQTY_EVENT",
            "FQTY_BUDGET",
            "FQTY_NEW",
            "FQTY_DISCONTINUED",
        ];

        if (validKeys.includes(keyValue)) {
            newEntry = {
                [keyValue]: parseInt(value),
                previousValue: prevValue,
                interval: interval,
            };
        }





        if (additionalFields.length > 0) {
            additionalFields.forEach((field, idx) => {
                newEntry[field] = details[idx];
            });
        } else {
            newEntry[selectedItem] = details[0];
            newEntry[selectedLocation] = details[1];
            newEntry[selectedCustomer] = details[2];
        }

        return newEntry;
    };


    const checkAllChangesArrayAreEmpty = (excludedArrayRef) => {
        // Get all state arrays
        const allArrays = {
            changes,
            salesChanges,
            eventChanges,
            marketChanges,
            promotionChanges,
            consensusChanges,
            budgetChanges,
            newChanges,
            disChanges
        };

        // Check if all other arrays are empty
        const isEmpty = Object.values(allArrays).every((array) => {
            if (array === excludedArrayRef) {
                return true; // Skip the excluded array
            }
            return array.length === 0; // Ensure all others are empty
        });

        if (!isEmpty) {
            message.info("Undo other changes to modify data here");
        }

        return isEmpty;
    };



    const [orderOfSortForSalesForecast, setorderOfSortForSalesForecast] = useState("")
    function sortMapBySumAvg(mapData, sortType, sortingOf) {
        // Helper function to calculate sum or average

        // uncheckAll();
        setKeySorting(false)
        setSortOrder(null);
        setSortedIndex(-1)
        var sortOrderOfNumbers = "";
        const calculateValue = (data, field) => {
            let total = 0;
            let count = 0;

            setsortString(`${sortType}-${sortingOf}`)
            if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "ascending" || orderOfSortForSalesForecast === "")) {
                sortOrderOfNumbers = "descending";
                setorderOfSortForSalesForecast("descending")

            }
            else if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "descending")) {
                sortOrderOfNumbers = "ascending";
                setorderOfSortForSalesForecast("ascending")

            }
            else if (sortString !== `${sortType}-${sortingOf}`) {
                sortOrderOfNumbers = "descending"
                setorderOfSortForSalesForecast("descending")
            }
            for (const date in data) {
                if (data[date][field] !== null) {
                    total += data[date][field];
                    count++;
                }
            }

            if (sortType === 'average' && count > 0) {
                return total / count;
            }

            return total;
        };

        // Determine which field to use for sorting (SQTY for sales or FQTY_USER for forecast)
        const field = sortingOf === 'sales' ? 'SQTY' : 'FQTY_USER';

        // Convert map into an array for sorting
        const sortedArray = Array.from(mapData).sort((a, b) => {
            const valueA = calculateValue(a[1], field);
            const valueB = calculateValue(b[1], field);

            if (sortOrderOfNumbers === 'ascending') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });


        setTransposedData(new Map(sortedArray))
        return new Map(sortedArray);
    }
    const { planningTableLoading } = useBase()

    const [colSpanNumber, setColSpanNumber] = useState(0);
    useEffect(() => {
        if (showAverage && showSum) {
            setColSpanNumber(2)
        }
        else if (showAverage || showSum) {
            setColSpanNumber(1)
        }
        else {
            setColSpanNumber(0)
        }
    }, [showAverage, showSum])



    const handleUndo = () => {
        const stateMapping = [
            { state: changes, setState: setChanges },
            { state: salesChanges, setState: setSalesChanges },
            { state: eventChanges, setState: setEventChanges },
            { state: marketChanges, setState: setMarketChanges },
            { state: promotionChanges, setState: setPromotionChanges },
            { state: consensusChanges, setState: setConsensusChanges },
            { state: newChanges, setState: setNewChanges },
            { state: disChanges, setState: setDisChanges },
            { state: budgetChanges, setState: setBudgetChanges },
        ];

        stateMapping.forEach(({ state, setState }) => {
            if (state.length !== 0) {
                setState(prevArray => prevArray.slice(0, -1));
            }
        });
    };

    const dropdownRefs = useRef([]);
    useEffect(() => {

        const handleClickOutside = (event) => {
            // Check if the click is outside of any of the dropdowns
            const isOutside = dropdownRefs.current.every(ref => ref && !ref.contains(event.target));
            if (isOutside) {
                setShowMultiCellConfigIndex(null); // Close the dropdown
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMultiCellConfigIndex]);


    useEffect(() => {
        setSelectedAdjType(null)
        setBucketLength(2)
    }, [showMultiCellConfigIndex])

    const handleSelectChange = (value) => {
        const referenceDate = dayjs(tdateVar);
        setQuickFilters(value);

        switch (fields['interval']) {
            case "FMONTH":
                if (value === "3m") {
                    setStartDate(referenceDate.subtract(3, 'month'));
                    setEndDate(referenceDate.add(3, 'month'));
                } else if (value === "6m") {
                    setStartDate(referenceDate.subtract(6, 'month'));
                    setEndDate(referenceDate.add(6, 'month'));
                } else if (value === "1y") {
                    setStartDate(referenceDate.subtract(1, 'year'));
                    setEndDate(referenceDate.add(1, 'year'));
                }
                break;

            case "FWEEK":
                if (value === "6w") {
                    setStartDate(referenceDate.subtract(6, 'week'));
                    setEndDate(referenceDate.add(6, 'week'));
                } else if (value === "12w") {
                    setStartDate(referenceDate.subtract(12, 'week'));
                    setEndDate(referenceDate.add(12, 'week'));
                } else if (value === "30w") {
                    setStartDate(referenceDate.subtract(30, 'week'));
                    setEndDate(referenceDate.add(30, 'week'));
                }
                break;

            case "FYEAR":
                if (value === "1y") {
                    setStartDate(referenceDate.subtract(1, 'year'));
                    setEndDate(referenceDate.add(1, 'year'));
                } else if (value === "3y") {
                    setStartDate(referenceDate.subtract(3, 'year'));
                    setEndDate(referenceDate.add(3, 'year'));
                } else if (value === "5y") {
                    setStartDate(referenceDate.subtract(5, 'year'));
                    setEndDate(referenceDate.add(5, 'year'));
                }
                break;

            default:
                break;
        }
    };

    const getOptions = () => {
        switch (fields['interval']) {
            case "FMONTH":
                return [
                    { label: "3+3 months", value: "3m" },
                    { label: "6+6 months", value: "6m" },
                    { label: "12+12 months", value: "1y" },
                ];
            case "FWEEK":
                return [
                    { label: "6+6 weeks", value: "6w" },
                    { label: "12+12 weeks", value: "12w" },
                    { label: "30+30 weeks", value: "30w" },
                ];
            case "FYEAR":
                return [
                    { label: "1+1 year", value: "1y" },
                    { label: "3+3 years", value: "3y" },
                    { label: "5+5 years", value: "5y" },
                ];
            default:
                return [];
        }
    };
    const getOptionsUnits = () => {

        return [
            { label: "Units", value: "units" },
            { label: "USD", value: "usd" },
            { label: "EUR", value: "eur" },
            { label: "INR", value: "inr" },
        ];

    };
    function findDetails(details) {
        // Iterate through each object in checkCommentsData

        if (checkCommentsData) {
            for (const data of checkCommentsData) {
                // Check if all elements of details are in Column_Values
                const columnValues = data.Column_Values || [];

                const filteredColumnValues = columnValues.filter((value) => value !== null);
                const allMatch = filteredColumnValues.every(value => details.includes(value));

                // If all details are found, return an object with POST_ID and FDATES
                if (allMatch) {
                    return {
                        POST_ID: data.POST_ID,
                        FDATES: data.FDATES || []
                    };
                }
            }
        }
        // Return null if no match is found
        return null;
    }
    function findTaskDetails(details) {
        // Iterate through each object in checkCommentsData

        if (checkTaskData) {
            for (const data of checkTaskData) {
                // Check if all elements of details are in Column_Values
                const columnValues = data.Column_Values || [];

                const filteredColumnValues = columnValues.filter((value) => value !== null);
                const allMatch = filteredColumnValues.every(value => details.includes(value));

                // If all details are found, return an object with POST_ID and FDATES
                if (allMatch) {

                    return {
                        TASK_ID: data.TASK_ID,
                        data: data.data || []
                    };
                }
            }
        }
        // Return null if no match is found
        return null;
    }


    return (
        <div className='flex flex-col space-y-2 w-full  h-full relative overflow-hidden'>

            {
                graphLoading && tableLoading ?
                    (<div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>)
                    :
                    <>
                        <div className='flex flex-row w-full justify-between'>
                            <div className="flex flex-row items-center text-sm w-full gap-2 pl-4 text-[#646666]">

                                <p >Version
                                    {fields['interval'] === "FWEEK" && (
                                        <strong>
                                            {` ${String(new Date(tdateVar).getDate()).padStart(2, '0')}-${String(new Date(tdateVar).getMonth() + 1).padStart(2, '0')}-${new Date(tdateVar).getFullYear().toString().slice(-2)} `}
                                        </strong>
                                    )}

                                    {fields['interval'] === "FMONTH" && (
                                        <strong>
                                            {` ${new Date(tdateVar).toLocaleDateString('en-US', { month: 'short' })}-${new Date(tdateVar).toLocaleDateString('en-US', { year: '2-digit' })} `}
                                        </strong>
                                    )}

                                    {fields['interval'] === "FYEAR" &&
                                        <strong>
                                            {` ${new Date(tdateVar).getFullYear().toString()} `}
                                        </strong>
                                    }

                                    {/* {new Date(tdateVar).toLocaleDateString('en-US', { month: 'short' })}-{new Date(tdateVar).toLocaleDateString('en-US', { year: '2-digit' })} */}

                                    showing from</p>
                                <DatePicker

                                    className="px-2 py-1 !text-xs custom-date-picker  bg-white rounded-full shadow-sm border border-gray-200 text-blue-600 max-w-28"
                                    value={startDate ? dayjs(startDate) : null}
                                    onChange={(date, dateString) => {
                                        setStartDate(dateString);
                                        setQuickFilters(null); // Clear the Select value
                                    }}
                                />
                                <p>to</p>
                                <DatePicker
                                    className="px-2 py-1 text-xs custom-date-picker  bg-white rounded-full shadow-sm border border-gray-200 text-blue-600 max-w-28"
                                    value={endDate ? dayjs(endDate) : null}
                                    onChange={(date, dateString) => {
                                        setEndDate(dateString);
                                        setQuickFilters(null); // Clear the Select value
                                    }}
                                />
                                <p>in</p>
                                <Select
                                    className="interval-selector rounded-full max-w-fit !text-blue-500"
                                    placeholder="Select Interval"
                                    value={fields.interval} // Controlled value
                                    onChange={(value) => {
                                        setIsFetchingPlan(false);
                                        setFields((prevState) => ({
                                            ...prevState,
                                            interval: value, // Update the interval field
                                        }));
                                    }}
                                    dropdownStyle={{ fontSize: '10px', width: "150px" }} // Optional: Customize dropdown width
                                >
                                    <Option value="FMONTH">Monthly</Option>
                                    <Option value="FWEEK">Weekly</Option>
                                    <Option value="FYEAR">Yearly</Option>
                                </Select>

                                <p>using a </p>
                                <Select
                                    className="interval-selector rounded-full max-w-fit !text-blue-500"
                                    placeholder="Custom"
                                    value={quickFilters} // Controlled value
                                    onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                    dropdownStyle={{ width: "150px" }}
                                >
                                    {getOptions().map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                                <p>view in</p>
                                <Select
                                    className="unit-selector interval-selector rounded-full max-w-20 !text-blue-500"
                                    defaultValue="units"
                                    // onChange={handleSelectChange}
                                    style={{ width: "100%" }}
                                >
                                    {getOptionsUnits().map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='flex flex-row items-center space-x-4'>

                                {
                                    ([changes, salesChanges, eventChanges, marketChanges, consensusChanges, promotionChanges, budgetChanges, newChanges, disChanges].some(array => array.length !== 0)) && !adjLoading ?
                                        <Button type='primary' className={`!bg-[#ed7d31]  hover:!bg-[#ec7323]`} onClick={() => {
                                            handleUndo()
                                        }}>
                                            Undo
                                        </Button>
                                        :
                                        null
                                }
                                {([changes, salesChanges, eventChanges, marketChanges, consensusChanges, promotionChanges, budgetChanges, newChanges, disChanges].some(array => array.length !== 0)) && !adjLoading &&
                                    <Button loading={submitLoading} type='primary' className={` flex justify-center text-center`} onClick={() => setOverlayFlag(!overlayFlag)}>
                                        Save
                                    </Button>
                                }
                                <div onClick={onDownload} className="flex flex-row  items-center border border-slate-300 hover:bg-slate-100 p-2 rounded-full cursor-pointer text-slate-400 font-bold text-lg" data-tooltip-id="my-tooltip" data-tooltip-content="Download">
                                    <FiDownload />
                                </div>
                                <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold" data-tooltip-id="my-tooltip" data-tooltip-content="Upload">
                                    <FiUpload />
                                </div>
                                <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold">
                                    <Dropdown
                                        menu={{
                                            items,
                                            onClick,
                                        }}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>

                                            <BsThreeDots />
                                        </a>
                                    </Dropdown>
                                </div>

                            </div>

                        </div>
                        {
                            !isExpanded ?
                                <div className='flex flex-row  items-center p-6 bg-white rounded-lg w-full shadow-sm'>
                                    {
                                        <CombinedGraph tdateVar={tdateVar} fields={fields} lineData1={lineData1} lineData2={lineData2} barData1={barData1} barData2={barData2} duration={fields["interval"]} />
                                    }
                                </div> : null
                        }

                        {planningTableLoading ?
                            (<div className='h-full flex justify-center items-center'><TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#2B3674"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /></div>)
                            :
                            <div ref={divRef} className={`w-full ${isExpanded ? 'max-h-full' : 'h-full'} overflow-scroll  border relative scroll   shadow-class`}>
                                {(
                                    <>
                                        <table ref={tempTableRef} className=' hidden resizable-table w-full relative whitespace-nowrap text-xs table-auto shadow-class bg-white  transition-all ease-in-out duration-500 '>
                                            <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                                <tr className='font-bold  bg-slate-100'>
                                                    {additionalFields.length === 0 &&

                                                        <>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 0)}
                                                                ref={col1Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['item']].toString().length > 15 ? uiMappingData[0][fields['item']] : ""}
                                                                style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px` }}
                                                                className={`sticky cursor-pointer ${sortedIndex === 0 && keySorting ? "bg-blue-100" : "bg-slate-100"}  shadow-col  left-[90px] z-40 overflow-hidden  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center px-3'>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['item']]}
                                                                    </div>


                                                                </div>


                                                            </td>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 1)}
                                                                ref={col2Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['location']].toString().length > 15 ? uiMappingData[0][fields['location']] : ""}
                                                                style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                className={`sticky  shadow-col cursor-pointer ${sortedIndex === 1 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden   z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center   px-3'>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['location']]}
                                                                    </div>


                                                                </div>

                                                            </td>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 2)}
                                                                ref={col3Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['category']].toString().length > 15 ? uiMappingData[0][fields['category']] : ""}
                                                                style={{ minWidth: `${columnWidths.col3}px`, maxWidth: `${columnWidths.col3}px`, left: `${columnWidths.col1 + columnWidths.col2 + 90}px` }}
                                                                className={`sticky shadow-col cursor-pointer  ${sortedIndex === 2 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden  z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200`}>
                                                                <div className='flex flex-row gap-1 items-center px-3'>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['category']]}
                                                                    </div>


                                                                </div>

                                                            </td>
                                                        </>
                                                    }
                                                    {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                        // Calculate the sum of all previous columns' widths
                                                        const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                            .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                            .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                        // Determine the field type
                                                        let fieldType = '';
                                                        let fieldKey = '';
                                                        if (item.toString()[0] === 'I') {
                                                            fieldType = 'item';
                                                            fieldKey = 'I';
                                                        } else if (item.toString()[0] === 'L') {
                                                            fieldType = 'location';
                                                            fieldKey = 'L';
                                                        } else if (item.toString()[0] === 'C') {
                                                            fieldType = 'category';
                                                            fieldKey = 'C';
                                                        }

                                                        // Function to handle change based on the field type
                                                        const handleChange = (e) => {
                                                            setFields({ ...fields, [fieldType]: e.target.value });
                                                            const updatedColumns = columnsShown.map(data => data === item ? e.target.value : data);
                                                            setColumnShown(updatedColumns);
                                                            fieldType === 'item' ? setSelectedItem(e.target.value) :
                                                                fieldType === 'location' ? setSelectedLocation(e.target.value) :
                                                                    setSelectedCustomer(e.target.value);
                                                        };
                                                        return (
                                                            <td
                                                                ref={columnRefs.current[`col${index}`]}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][item].toString().length > 15 ? uiMappingData[0][item] : ""}
                                                                onDoubleClick={() => sortData(trasnposedData, index)}
                                                                style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                key={index}
                                                                className={`sticky ${sortedIndex === index && keySorting ? "bg-blue-100" : "bg-slate-100"} shadow-col overflow-hidden cursor-pointer px-3 text-xs py-1 border-1 border-gray-200 z-40`}
                                                            >
                                                                <div className='flex flex-row gap-1 items-center  px-3'>

                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][item]}
                                                                    </div>

                                                                </div>

                                                            </td>
                                                        );
                                                    })}
                                                    {
                                                        showAverage &&
                                                        <>
                                                            <td>Sales avg</td>
                                                            <td>Forecast avg</td>
                                                        </>
                                                    }
                                                    {
                                                        showSum &&

                                                        <>
                                                            <td>Sales sum</td>
                                                            <td>Forecast sum</td>
                                                        </>
                                                    }


                                                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {




                                                        if (checkForMidDate(item, tdateVar) && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                            midIndex = index
                                                            return (<React.Fragment key={index}>
                                                                <td colSpan={2} className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-1 justify-between'>
                                                                        <div className='flex flex-col items-start'>

                                                                            <div className='flex w-full justify-between '>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>



                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }
                                                        else {
                                                            if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 4) {
                                                                return (<React.Fragment key={index}>
                                                                    <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                        <div className='flex flex-row items-center gap-3 justify-between'>
                                                                            <div className='flex flex-col items-start'>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>


                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>)
                                                            }
                                                            if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                                return (<React.Fragment key={index}>
                                                                    <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                        <div className='flex flex-row items-center gap-3 justify-between'>
                                                                            <div className='flex flex-col items-start'>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>)
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {trasnposedData.size !== 0 && [...trasnposedData.keys()].map((data, rowIndex) => {
                                                    const details = data.split("=");
                                                    return (
                                                        <>

                                                            <tr

                                                                className={`font-semibold  transition-all ease-in-out  duration-500 ${isIndexPresent(rowIndex) ? 'sticky z-50 shadow-col left-0 h-8' : ''}`}
                                                                style={isIndexPresent(rowIndex) ? { top: `${getTopForIndex(rowIndex)}rem` } : {}}
                                                                key={rowIndex}>


                                                                {/* <td className='sticky min-w-[30px] shadow-col bg-white left-0 z-30 transform-all ease-in-out duration-500 h-full font-bold text-lg text-center hover:bg-slate-100 cursor-pointer'></td> */}



                                                                {
                                                                    additionalFields.length === 0 &&
                                                                    <>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px`, left: `${90}px` }}
                                                                            className={`sticky z-30 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4    text-left  py-2 text-xs  `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[0].toString().length > 22 ? details[0] : ""}
                                                                        >
                                                                            {details[0] !== null ? details[0] : "Not Available"}
                                                                        </td>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                            className={`sticky z-30 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4     text-left  py-2 text-xs `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[1].toString().length > 22 ? details[1] : ""}
                                                                        >
                                                                            {details[1] !== null ? details[1] : "Not Available"}
                                                                        </td>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col3}px`, maxWidth: `${columnWidths.col3}px`, left: `${columnWidths.col1 + columnWidths.col2 + 90}px` }}
                                                                            className={`sticky z-30 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4   text-left  py-2 text-xs  `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[2].toString().length > 22 ? details[2] : ""}
                                                                        >
                                                                            {details[2] !== null ? details[2] : "Not Available"}
                                                                        </td>
                                                                    </>
                                                                }
                                                                {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                                    const sp = (90 + ((index) * 180))

                                                                    const leftValue = `${sp}px`;

                                                                    // Calculate the sum of all previous columns' widths
                                                                    const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                                        .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                                        .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                                    return (
                                                                        <td
                                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={details[index] !== null && details[index].toString().length > 22 ? details[index] : ""}
                                                                            style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                            className={`sticky z-30 text-left overflow-hidden px-3 py-2
                                                                         shadow-col   text-xs ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  `} key={index}>{details[index] !== null ? details[index] : "Not Available"}</td>
                                                                    )

                                                                })}
                                                                {
                                                                    showAverage &&

                                                                    <>
                                                                        <td>{calculateSumAndAvg(trasnposedData.get(data), 'sales', false)}</td>
                                                                        <td>{calculateSumAndAvg(trasnposedData.get(data), 'forecast', false)}</td>
                                                                    </>

                                                                }
                                                                {
                                                                    showSum &&
                                                                    <>
                                                                        <td>{calculateSumAndAvg(trasnposedData.get(data), 'sales', true)}</td>
                                                                        <td>{calculateSumAndAvg(trasnposedData.get(data), 'forecast', true)}</td>
                                                                    </>


                                                                }


                                                                {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                                                                    if (item["isSplit"] === true) {
                                                                        return (
                                                                            <>
                                                                                <td className={`
            ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"} min-w-[80px] max-w-[80px]`}>
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>

                                                                                        <p
                                                                                            onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "SQTY")}
                                                                                            className='p-2 h-full w-full flex items-center justify-between '>{(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(0)).toString() : 0)}

                                                                                        </p>

                                                                                    </div>
                                                                                </td>
                                                                                <td

                                                                                    className={`
                text-left text-xs min-w-[80px] max-w-[80px] px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                            ? ' text-white'
                                                                                            : 'bg-gray-50 text-[#4285F4]'
                                                                                        } ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                                >
                                                                                    {(
                                                                                        <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                            <p
                                                                                                className={`p-2 h-full w-full flex items-center justify-between 
                ${item["FQTY"] !== null ?
                                                                                                        item["FQTY"] === item["FQTY_USER"] ?
                                                                                                            "" : "text-white bg-[#ed7d31]"
                                                                                                        :
                                                                                                        ""}`}
                                                                                            >{(item["FQTY"] !== null ?
                                                                                                (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    parseFloat(Number(item["FQTY"]).toFixed(0)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(0)).toString())
                                                                                                :
                                                                                                (0))}

                                                                                            </p>

                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                            </>
                                                                        );
                                                                    }
                                                                    // for normal sqty
                                                                    else if (item["SQTY"] !== null && item["SQTY"] !== 0 && index < midIndex) {
                                                                        return (
                                                                            <td
                                                                                className={`
            text-left min-w-[110px] max-w-[110px]  text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#646666]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}>
                                                                                {(
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                        <p
                                                                                            className='p-2 h-full w-full flex items-center justify-between '>{(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(0)).toString() : 0)}

                                                                                        </p>

                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }

                                                                    // for normal fqty
                                                                    else if ((item["FQTY"] !== null && index > midIndex) || (item["SQTY"] === null && item["FQTY"] === null && index > midIndex)) {
                                                                        return (
                                                                            <td

                                                                                className={`
             text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                        ? 'text-white'
                                                                                        : 'bg-gray-50 text-[#4285F4]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                            >
                                                                                {
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                        <p
                                                                                            className={`p-2 h-full w-full flex items-center justify-between ${item["FQTY"] !== null ?
                                                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    "" : "text-white bg-[#ed7d31]"
                                                                                                :
                                                                                                ""}`}
                                                                                        >
                                                                                            {(item["FQTY"] !== null ?
                                                                                                (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    parseFloat(Number(item["FQTY"]).toFixed(0)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(0)).toString())
                                                                                                :
                                                                                                (0))}

                                                                                        </p>


                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        );
                                                                    }

                                                                    // for 0 sqty
                                                                    else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                                                                        return (
                                                                            <td className={`
        ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}  min-w-[110px] max-w-[110px]`}>
                                                                                <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                    <p
                                                                                        onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                        className='p-2 h-full w-full flex items-center justify-between ' >{(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(0)).toString() : 0)}

                                                                                    </p>

                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    }




                                                                })}
                                                            </tr>

                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <table ref={tableRef} className='resizable-table w-full relative whitespace-nowrap text-xs table-auto shadow-class bg-white  transition-all ease-in-out duration-500 '>
                                            <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                                <tr className='font-bold  bg-slate-100'>
                                                    {/* <td className='sticky bg-slate-100 shadow-col left-0 z-40 min-w-[30px]  text-center text-base text-blue-500  no-download-col'>
                                                    <button className='w-full h-full' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                </td> */}
                                                    <td className='sticky bg-slate-100 shadow-col left-0 min-w-[90px]   z-50 text-center text-base '>
                                                        <div className='flex flex-row items-center justify-between px-3'>
                                                            <div className='flex items-center'>
                                                                <button className='w-full h-full hover:text-blue-400' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                            </div>
                                                            <div
                                                                className=" hover:text-blue-500 text-sm">
                                                                {
                                                                    !isExpanded ?
                                                                        <ExpandOutlined className='cursor-pointer'
                                                                            onClick={() => {
                                                                                setIsExpanded(true);
                                                                            }} />
                                                                        :
                                                                        <IoMdContract className='cursor-pointer'
                                                                            onClick={() => {
                                                                                setIsExpanded(false);
                                                                            }} />
                                                                }

                                                            </div>

                                                            <div onClick={() => uncheckAll()} className='hover:text-blue-500 cursor-pointer'>

                                                                <CgRemoveR />
                                                            </div>

                                                        </div>

                                                    </td>
                                                    {additionalFields.length === 0 &&

                                                        <>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 0)}
                                                                ref={col1Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['item']].toString().length > 15 ? uiMappingData[0][fields['item']] : ""}
                                                                style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px` }}
                                                                className={`sticky cursor-pointer ${sortedIndex === 0 && keySorting ? "bg-blue-100" : "bg-slate-100"}  shadow-col  left-[90px] z-50 overflow-hidden  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(fields['item']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    {/* <div className='text-base  flex flex-col justify-start items-center '>
                                                                    <div onClick={() => sortData(trasnposedData, 0)} className={`${sortOrder === true && sortedIndex === 0 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortUp />
                                                                    </div>
                                                                    <div onClick={() => sortData(trasnposedData, 0)} className={`${sortOrder === false && sortedIndex === 0 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortDown />
                                                                    </div>
                                                                </div> */}
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['item']]}
                                                                    </div>
                                                                    <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                            onChange={(e) => {
                                                                                setFields({ ...fields, "item": e.target.value });
                                                                                setSelectedItem(e.target.value);
                                                                            }}
                                                                            value=""
                                                                        >
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                if (item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null) {
                                                                                    return (
                                                                                        <option key={item} value={item}>
                                                                                            {uiMappingData[0][item]}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </select>

                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className="resize-handle"
                                                                    onMouseDown={(e) => handleMouseDown(e, 'col1', col1Ref)}
                                                                />

                                                            </td>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 1)}
                                                                ref={col2Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['location']].toString().length > 15 ? uiMappingData[0][fields['location']] : ""}
                                                                style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                className={`sticky  shadow-col cursor-pointer ${sortedIndex === 1 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden   z-50 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 `}>
                                                                <div className='flex flex-row gap-1 items-center   px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(fields['location']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    {/* <div className='text-base  flex flex-col justify-start items-center '>
                                                                    <div onClick={() => sortData(trasnposedData, 1, true)} className={`${sortOrder === true && sortedIndex === 1 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortUp />
                                                                    </div>
                                                                    <div onClick={() => sortData(trasnposedData, 1, false)} className={`${sortOrder === false && sortedIndex === 1 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortDown />
                                                                    </div>
                                                                </div> */}
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['location']]}
                                                                    </div>
                                                                    <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                            onChange={(e) => {
                                                                                setFields({ ...fields, "location": e.target.value });
                                                                                setSelectedLocation(e.target.value)
                                                                            }}
                                                                            value={""}>
                                                                            <>
                                                                                <option value="" disabled hidden></option>
                                                                                {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                    if (item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null) {
                                                                                        return (
                                                                                            <option value={item}>{uiMappingData[0][item]}</option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className="resize-handle"
                                                                    onMouseDown={(e) => handleMouseDown(e, 'col2', col2Ref)}
                                                                />
                                                            </td>
                                                            <td
                                                                onDoubleClick={() => sortData(trasnposedData, 2)}
                                                                ref={col3Ref}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][fields['category']].toString().length > 15 ? uiMappingData[0][fields['category']] : ""}
                                                                style={{ minWidth: `${columnWidths.col3}px`, maxWidth: `${columnWidths.col3}px`, left: `${columnWidths.col1 + columnWidths.col2 + 90}px` }}
                                                                className={`sticky shadow-col cursor-pointer  ${sortedIndex === 2 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden  z-50 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200`}>
                                                                <div className='flex flex-row gap-1 items-center px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(fields['category']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][fields['category']]}
                                                                    </div>
                                                                    <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                            onChange={(e) => {
                                                                                setFields({ ...fields, "category": e.target.value });
                                                                                setSelectedCustomer(e.target.value)
                                                                            }}
                                                                            value={""}>
                                                                            <>
                                                                                <option value="" disabled hidden></option>

                                                                                {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                    if (item.toString().substring(0, 1) === "C" && uiMappingData[0][item] !== null) {
                                                                                        return (
                                                                                            <option value={item}>{uiMappingData[0][item]}</option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className="resize-handle"
                                                                    onMouseDown={(e) => handleMouseDown(e, 'col3', col3Ref)}
                                                                />
                                                            </td>
                                                        </>
                                                    }
                                                    {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                        // Calculate the sum of all previous columns' widths
                                                        const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                            .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                            .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                        // Determine the field type
                                                        let fieldType = '';
                                                        let fieldKey = '';
                                                        if (item.toString()[0] === 'I') {
                                                            fieldType = 'item';
                                                            fieldKey = 'I';
                                                        } else if (item.toString()[0] === 'L') {
                                                            fieldType = 'location';
                                                            fieldKey = 'L';
                                                        } else if (item.toString()[0] === 'C') {
                                                            fieldType = 'category';
                                                            fieldKey = 'C';
                                                        }

                                                        // Function to handle change based on the field type
                                                        const handleChange = (e) => {
                                                            setFields({ ...fields, [fieldType]: e.target.value });
                                                            const updatedColumns = columnsShown.map(data => data === item ? e.target.value : data);
                                                            setColumnShown(updatedColumns);
                                                            fieldType === 'item' ? setSelectedItem(e.target.value) :
                                                                fieldType === 'location' ? setSelectedLocation(e.target.value) :
                                                                    setSelectedCustomer(e.target.value);
                                                        };
                                                        return (
                                                            <td
                                                                ref={columnRefs.current[`col${index}`]}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][item].toString().length > 15 ? uiMappingData[0][item] : ""}
                                                                onDoubleClick={() => sortData(trasnposedData, index)}
                                                                style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                key={index}
                                                                className={`sticky ${sortedIndex === index && keySorting ? "bg-blue-100" : "bg-slate-100"} shadow-col overflow-hidden cursor-pointer px-3 text-xs py-1 border-1 border-gray-200 z-50`}
                                                            >
                                                                <div className='flex flex-row gap-1 items-center  px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(item) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][item]}
                                                                    </div>
                                                                    <div className='plan-select cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none appearance-none px-1 relative`}
                                                                            onChange={handleChange}
                                                                            value={""}
                                                                        >
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((data) => {
                                                                                if (data.toString()[0] === fieldKey && uiMappingData[0][data] !== null) {
                                                                                    return (
                                                                                        <option value={data} selected={uiMappingData[0][item] === uiMappingData[0][data]}>
                                                                                            {uiMappingData[0][data]}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='resize-handle'
                                                                    style={{ cursor: 'col-resize', padding: '0 5px' }}
                                                                    onMouseDown={(e) => handleMouseDownDynamic(e, `col${index}`)}
                                                                />
                                                            </td>
                                                        );
                                                    })}
                                                    {
                                                        showAverage &&

                                                        <td

                                                            className='sticky z-50 shadow-col min-w-[130px] max-w-[130px] bg-slate-100 p-0'
                                                            style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90)}px` }}
                                                        >
                                                            <div className=' w-full flex flex-col h-full'>
                                                                <div className='flex w-full pb-1'>

                                                                    <p className={`text-center w-full`}>Average</p>

                                                                </div>

                                                                <div className='flex justify-between font-normal border-t text-slate-400'>
                                                                    <p onDoubleClick={() => sortMapBySumAvg(trasnposedData, 'average', 'sales')} className={`border-r w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'average-sales' ? "text-green-600" : ""}`}>Sales</p>
                                                                    <p onDoubleClick={() => sortMapBySumAvg(trasnposedData, 'average', 'forecast')} className={`w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'average-forecast' ? "text-green-600" : ""}`}>Forecast</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                    {
                                                        showSum &&

                                                        <td

                                                            className='sticky z-50 shadow-col min-w-[130px] max-w-[130px] bg-slate-100 p-0'
                                                            style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 + (showAverage ? 130 : 0) : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90 + (showAverage ? 130 : 0))}px` }}
                                                        >
                                                            <div className=' w-full flex flex-col h-full'>
                                                                <div className='flex w-full pb-1'>

                                                                    <p className={`text-center w-full`}>Sum</p>

                                                                </div>

                                                                <div className='flex justify-between font-normal border-t text-slate-400'>
                                                                    <p onDoubleClick={() => sortMapBySumAvg(trasnposedData, 'sum', 'sales')} className={`border-r w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'sum-sales' ? "text-green-600" : ""}`}>Sales</p>
                                                                    <p onDoubleClick={() => sortMapBySumAvg(trasnposedData, 'sum', 'forecast')} className={`w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'sum-forecast' ? "text-green-600" : ""}`}>Forecast</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }


                                                    {trasnposedData.size !== 0 && Object.keys(trasnposedData.get([...trasnposedData.keys()][0])).map((item, index) => {




                                                        if (checkForMidDate(item, tdateVar) && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                            midIndex = index
                                                            return (<React.Fragment key={index}>
                                                                <td ref={tdRef} colSpan={2} className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-1 justify-between'>
                                                                        <div className='flex flex-col items-start'>

                                                                            <div className='flex w-full justify-between '>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>

                                                                                <div className='flex items-center text-base'>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 pt-2  cursor-pointer  hover:text-blue-500`}>
                                                                                        <FaSortUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 pb-2  cursor-pointer  hover:text-blue-500`}>
                                                                                        <FaSortDown />
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div className='flex justify-between gap-6 w-full ' >
                                                                                <p className='text-slate-400 font-normal'>
                                                                                    {Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(totalSqty[index])}
                                                                                </p>
                                                                                <p className='text-slate-400 font-normal'>
                                                                                    {Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(totalFqty[index])}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='text-base  flex flex-col justify-start items-center '>
                                                                            <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortUp />
                                                                            </div>
                                                                            <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortDown />
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }
                                                        else {
                                                            if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 4) {
                                                                return (<React.Fragment key={index}>
                                                                    <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                        <div className='flex flex-row items-center gap-3 justify-between'>
                                                                            <div className='flex flex-col items-start'>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>

                                                                                <p className='text-slate-400 font-normal gap-2 flex justify-between items-center'>
                                                                                    <span>

                                                                                        {Intl.NumberFormat('en-US', {
                                                                                            notation: "compact",
                                                                                            maximumFractionDigits: 1
                                                                                        }).format(index < midIndex ? totalSqty[index] : totalFqty[index])}
                                                                                    </span>
                                                                                    {/* <span className='flex items-center justify-center text-lg'>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedDown />
                                                                                    </div>
                                                                                </span> */}
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-base  flex flex-col justify-start items-center '>
                                                                                <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                    <FaSortUp />
                                                                                </div>
                                                                                <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                    <FaSortDown />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>)
                                                            }
                                                            if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                                return (<React.Fragment key={index}>
                                                                    <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""} z-40  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                        <div className='flex flex-row items-center gap-3 justify-between'>
                                                                            <div className='flex flex-col items-start'>
                                                                                <p>
                                                                                    {fields['interval'] === "FWEEK" &&
                                                                                        `${String(new Date(item).getDate()).padStart(2, '0')}-${String(new Date(item).getMonth() + 1).padStart(2, '0')}-${new Date(item).getFullYear().toString().slice(-2)}`}
                                                                                    {fields['interval'] === "FMONTH" &&
                                                                                        `${new Date(item).toLocaleDateString('en-US', { month: 'short' })}-${new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}`}
                                                                                    {fields['interval'] === "FYEAR" &&
                                                                                        new Date(item).getFullYear().toString()}
                                                                                </p>

                                                                                <p className='text-slate-400 font-normal gap-2 flex justify-between items-center'>
                                                                                    <span>

                                                                                        {Intl.NumberFormat('en-US', {
                                                                                            notation: "compact",
                                                                                            maximumFractionDigits: 1
                                                                                        }).format(index < midIndex ? totalSqty[index] : totalFqty[index])}
                                                                                    </span>
                                                                                    {/* <span className='flex items-center justify-center text-lg'>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedDown />
                                                                                    </div>
                                                                                </span> */}
                                                                                </p>
                                                                            </div>
                                                                            <div className='text-base  flex flex-col justify-start items-center '>
                                                                                <div onClick={() => sortDataByDate(item, trasnposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                    <FaSortUp />
                                                                                </div>
                                                                                <div onClick={() => sortDataByDate(item, trasnposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                    <FaSortDown />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>)
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className=''>
                                                {trasnposedData.size !== 0 && [...trasnposedData.keys()].map((data, rowIndex) => {
                                                    const details = data.split("=");
                                                    const result = findDetails(details);
                                                    const taskResults = findTaskDetails(details)
                                                    // console.log("---taskResults------",taskResults);
                                                    // console.log("---result------",result);
                                                    return (
                                                        <>
                                                            <tr

                                                                className={`font-semibold  transition-all ease-in-out  duration-500 ${isIndexPresent(rowIndex) ? 'sticky z-50 shadow-col left-0 h-8' : ''}`}
                                                                style={isIndexPresent(rowIndex) ? { top: `${getTopForIndex(rowIndex)}rem` } : {}}
                                                                key={rowIndex}>


                                                                {/* <td className='sticky min-w-[30px] shadow-col bg-white left-0 z-30 transform-all ease-in-out duration-500 h-full font-bold text-lg text-center hover:bg-slate-100 cursor-pointer'></td> */}

                                                                {<td className={`sticky ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  ${showMultiCellConfigIndex === rowIndex ? "z-50" : "z-40"}  shadow-col left-0 min-w-[90px]  text-center text-base no-download-col`}>
                                                                    <div className='flex flex-row items-center px-2 justify-between'>


                                                                        <div
                                                                            onClick={() => {
                                                                                if ((expandAllRows) || (expandRow === rowIndex)) {
                                                                                    setExpandRow(null)
                                                                                    setExpandAllRows(false)
                                                                                }
                                                                                else {
                                                                                    setExpandRow(rowIndex)
                                                                                    getProposals(details)
                                                                                }
                                                                            }
                                                                            }
                                                                            className={`transform-all cursor-pointer hover:text-blue-500 ease-in-out duration-200 ${(expandAllRows) || (expandRow === rowIndex) ? "rotate-180 transform-all ease-in-out duration-200" : ""}`}>
                                                                            < MdOutlineExpandCircleDown />
                                                                        </div>
                                                                        <div className='z-40 pl-1 ' ref={(el) => dropdownRefs.current[rowIndex] = el}>
                                                                            <div>
                                                                                <Button onClick={() => { setShowMultiCellConfigIndex(rowIndex) }} type='link' className={`!p-0  hover:!text-blue-500  ${showMultiCellConfigIndex === rowIndex ? "!text-blue-500" : "!text-gray-500"} `}>
                                                                                    <TiFlashOutline />

                                                                                </Button>
                                                                            </div>
                                                                            {
                                                                                showMultiCellConfigIndex === rowIndex &&
                                                                                <div className={`absolute top-0 left-14 text-xs font-normal`}>
                                                                                    <div className='bg-white shadow-md flex flex-col rounded-md border border-blue-500'>
                                                                                        <p className='font-semibold text-base text-bodyTextBlue px-2 py-1 rounded-tl-md rounded-tr-md border-b-2 text-start bg-slate-100'>Quick Actions</p>
                                                                                        <p onClick={() => setSelectedAdjType("0")} className={`px-2 py-1  ${selectedAdjType === "0" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"} rounded-md w-full  text-start cursor-pointer`}>Make all forecasts 0</p>
                                                                                        <hr />
                                                                                        <p onClick={() => setSelectedAdjType("prev years")} className={`px-2 py-1 ${selectedAdjType === "prev years" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"}  rounded-md w-full text-start cursor-pointer`}>Make all forecasts same as previous year same period</p>
                                                                                        <hr />
                                                                                        <div onClick={() => setSelectedAdjType("avg")} className={`px-2 py-1 ${selectedAdjType === "avg" ? "bg-[#ed7d31] text-white" : "hover:bg-slate-100"} rounded-md w-full text-start cursor-pointer`}>
                                                                                            Make all forecasts average of previous buckets
                                                                                            {
                                                                                                selectedAdjType == "avg" &&
                                                                                                <div className='flex gap-2 mt-1'>
                                                                                                    <label htmlFor="bucketLength">Enter bucket numbers</label>
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        name="bucketLength"
                                                                                                        id="bucketLength"
                                                                                                        className="w-11 h-5 border-2 pl-2 border-slate-300 rounded-md text-black"
                                                                                                        min={2}
                                                                                                        value={bucketLength}
                                                                                                        onChange={(e) => setBucketLength(e.target.value)}
                                                                                                    />
                                                                                                </div>
                                                                                            }

                                                                                            {/* <span className='pl-4'>
                                                                                                <Button onClick={() => saveRowAdjustment("avg")} type='primary' className='!p-2 h-6 !text-xs'>
                                                                                                    Submit
                                                                                                </Button>
                                                                                            </span> */}
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className='flex justify-end pr-2 pb-2 pt-2'>
                                                                                            <Button onClick={() => setShowMultiCellConfigIndex(null)} type='link' className='!p-2 h-6 !text-xs'>
                                                                                                Cancel
                                                                                            </Button>
                                                                                            <Button onClick={() => saveRowAdjustment(details)} type='primary' className='!p-2 h-6 !text-xs'>
                                                                                                Submit
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className=''>

                                                                            <input type='checkbox'
                                                                                ref={(el) => (checkboxRefs.current[rowIndex] = el)} // Assign ref to each checkbox
                                                                                onKeyDown={(e) => handleKeyDown(e, rowIndex)} // Handle up/down keys
                                                                                id="childCheckbox"
                                                                                checked={selectedItems.includes(data)}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        // const correspondingObject = trasnposedData.get(data);
                                                                                        // checkedGraphData(correspondingObject, rowIndex)
                                                                                        addCheckIndex(rowIndex)
                                                                                        setSelectedItems(prevState => [...prevState, data])
                                                                                    }
                                                                                    else {
                                                                                        // const correspondingObject = trasnposedData.get(data);
                                                                                        // uncheckGraphData(correspondingObject, rowIndex)
                                                                                        removeCheckIndex(rowIndex)
                                                                                        setSelectedItems(selectedItems.filter(item => item !== data))
                                                                                    }
                                                                                }} className='text-center text-base ' />
                                                                        </div>
                                                                    </div>
                                                                </td>}

                                                                {
                                                                    additionalFields.length === 0 &&
                                                                    <>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px`, left: `${90}px` }}
                                                                            className={`sticky z-40 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4    text-left  py-2 text-xs  `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[0].toString().length > 22 ? details[0] : ""}
                                                                        >
                                                                            {details[0] !== null ? details[0] : "Not Available"}
                                                                        </td>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                            className={`sticky z-40 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4     text-left  py-2 text-xs `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[1].toString().length > 22 ? details[1] : ""}
                                                                        >
                                                                            {details[1] !== null ? details[1] : "Not Available"}
                                                                        </td>
                                                                        <td
                                                                            style={{ minWidth: `${columnWidths.col3}px`, maxWidth: `${columnWidths.col3}px`, left: `${columnWidths.col1 + columnWidths.col2 + 90}px` }}
                                                                            className={`sticky z-40 overflow-hidden ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  shadow-col px-4   text-left  py-2 text-xs  `}
                                                                        // data-tooltip-id="my-tooltip" data-tooltip-content={details[2].toString().length > 22 ? details[2] : ""}
                                                                        >
                                                                            {details[2] !== null ? details[2] : "Not Available"}
                                                                        </td>
                                                                    </>
                                                                }
                                                                {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                                    const sp = (90 + ((index) * 180))

                                                                    const leftValue = `${sp}px`;

                                                                    // Calculate the sum of all previous columns' widths
                                                                    const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                                        .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                                        .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                                    return (
                                                                        <td
                                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={details[index] !== null && details[index].toString().length > 22 ? details[index] : ""}
                                                                            style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                            className={`sticky z-40 text-left overflow-hidden px-3 py-2
                                                                         shadow-col   text-xs ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}  `} key={index}>{details[index] !== null ? details[index] : "Not Available"}</td>
                                                                    )

                                                                })}
                                                                {
                                                                    showAverage &&

                                                                    <td
                                                                        className={`sticky z-40 shadow-col min-w-[130px] max-w-[130px] p-0 ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}`}
                                                                        style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90)}px` }}
                                                                    >
                                                                        <div className='flex justify-between font-normal min-h-full'>
                                                                            <p className='border-r w-full text-center'>
                                                                                {calculateSumAndAvg(trasnposedData.get(data), 'sales', false)}
                                                                            </p>
                                                                            <p className='w-full text-center'>
                                                                                {calculateSumAndAvg(trasnposedData.get(data), 'forecast', false)}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                }
                                                                {
                                                                    showSum &&

                                                                    <td
                                                                        className={`sticky z-40 shadow-col min-w-[130px] max-w-[130px] p-0 ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-white"}`}
                                                                        style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 + (showAverage ? 130 : 0) : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90 + (showAverage ? 130 : 0))}px` }}
                                                                    >
                                                                        <div className='flex justify-between font-normal min-h-full'>
                                                                            <p className='border-r w-full text-center'>
                                                                                {calculateSumAndAvg(trasnposedData.get(data), 'sales', true)}
                                                                            </p>
                                                                            <p className='w-full text-center'>
                                                                                {calculateSumAndAvg(trasnposedData.get(data), 'forecast', true)}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                }


                                                                {trasnposedData.size !== 0 && Object.values(trasnposedData.get(data)).map((item, index) => {

                                                                    const isCommentPresent = result && result.FDATES.includes(Object.keys(trasnposedData.get(data))[index])
                                                                    let postID;
                                                                    let fdate = Object.keys(trasnposedData.get(data))[index]
                                                                    if (isCommentPresent) {
                                                                        postID = result.POST_ID

                                                                    }
                                                                    let taskID = null
                                                                    const isTaskPresent = taskResults && taskResults?.data?.some(entry => entry.FDATE === fdate && (entry.QTY === 'FQTY_ENGINE' || entry.QTY === 'SQTY'));


                                                                    if (isTaskPresent) {
                                                                        taskID = taskResults.TASK_ID
                                                                        // console.log("taskResults.TASK_ID----------------",taskResults.TASK_ID);
                                                                    }

                                                                    if (item["isSplit"] === true) {
                                                                        return (
                                                                            <>
                                                                                <td className={`
                                                                                ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "SQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                                ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"} min-w-[80px] max-w-[80px]`}>
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>

                                                                                        <p
                                                                                            onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "SQTY")}
                                                                                            className='p-2 h-full w-full flex items-center justify-between '>{Intl.NumberFormat('en-US', {
                                                                                                notation: "compact",
                                                                                                maximumFractionDigits: 1
                                                                                            }).format(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0)}
                                                                                            <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "SQTY", postID, fdate, taskID)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>
                                                                                                <LuInfo />
                                                                                            </Button>
                                                                                        </p>
                                                                                        {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "SQTY" && (
                                                                                            <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} qty={'SQTY'} />
                                                                                        )}
                                                                                        {
                                                                                            isCommentPresent &&
                                                                                            <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"SQTY"} postID={postID} fdate={fdate} />
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    onDoubleClick={() => {
                                                                                        if (checkAllChangesArrayAreEmpty(changes)) {
                                                                                            setChanges((prevState) => [
                                                                                                ...prevState,
                                                                                                createNewEntry(item.FQTY_USER, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                            ]);
                                                                                        }
                                                                                    }}
                                                                                    className={`
                                                                                    ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                                    text-left text-xs min-w-[80px] max-w-[80px] px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                            ? ' text-white'
                                                                                            : 'bg-gray-50 text-[#4285F4]'
                                                                                        } ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                                >
                                                                                    {changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            defaultValue={
                                                                                                item["FQTY"] !== null
                                                                                                    ? item["FQTY"] === item["FQTY_USER"]
                                                                                                        ? parseFloat(Number(item["FQTY"]).toFixed(2)).toString()
                                                                                                        : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString()
                                                                                                    : (0)
                                                                                            }
                                                                                            className={`p-2 h-full w-full bg-[#4285F4] ${item["FQTY"] !== null && item["FQTY"] !== item["FQTY_USER"]
                                                                                                ? "text-white bg-[#ed7d31]"
                                                                                                : ""}`}
                                                                                            onChange={(e) => {
                                                                                                e.target.className = `p-2 h-full w-full ${e.target.value !== item["FQTY"] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                                                                                    }`;
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                setChanges((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                                                                                setChanges((prevState) => [
                                                                                                    ...prevState,
                                                                                                    createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                                ]);
                                                                                            }}
                                                                                            autoFocus
                                                                                        />
                                                                                    ) : (
                                                                                        <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                            <p
                                                                                                onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                                className={`p-2 h-full w-full flex items-center justify-between 
                                                                                    ${item["FQTY"] !== null ?
                                                                                                        item["FQTY"] === item["FQTY_USER"] ?
                                                                                                            "" : "text-white bg-[#ed7d31]"
                                                                                                        :
                                                                                                        ""}`}
                                                                                            >{Intl.NumberFormat('en-US', {
                                                                                                notation: "compact",
                                                                                                maximumFractionDigits: 1
                                                                                            }).format(item["FQTY"] !== null ?
                                                                                                (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    parseFloat(Number(item["FQTY"]).toFixed(2)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString())
                                                                                                :
                                                                                                (0))}
                                                                                                <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "FQTY_ENGINE", postID, fdate, taskID)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                                                                                    <LuInfo />

                                                                                                </Button>
                                                                                            </p>
                                                                                            {
                                                                                            isTaskPresent &&
                                                                                            <div
                                                                                                className='absolute bottom-0 right-0 w-1 h-1 triangle-bottomright  transition-all duration-300 ease-in-out cursor-pointer'>
                                                                                            </div>}
                                                                                            {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" && (
                                                                                                <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} qty={'FQTY_ENGINE'} />
                                                                                            )}
                                                                                            {
                                                                                                isCommentPresent &&
                                                                                                <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"FQTY"} postID={postID} fdate={fdate} />
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                            </>
                                                                        );
                                                                    }
                                                                    // for normal sqty
                                                                    else if (item["SQTY"] !== null && item["SQTY"] !== 0 && index < midIndex) {
                                                                        return (
                                                                            <td
                                                                                className={`
                                                                                ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                                text-left min-w-[110px] max-w-[110px]  text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#646666]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}>
                                                                                {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0}
                                                                                        className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.value !== item["SQTY"]) {
                                                                                                e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                            }
                                                                                            else {
                                                                                                e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                            }
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setChanges(prevArray => prevArray.slice(0, -1))
                                                                                            setChanges((prevState) => [...prevState, {
                                                                                                SQTY_USER: parseInt(e.target.value),
                                                                                                interval: intervalFiltered[index],
                                                                                                [selectedItem]: details[0],
                                                                                                [selectedLocation]: details[1],
                                                                                                [selectedCustomer]: details[2]
                                                                                            }])
                                                                                        }}
                                                                                        autoFocus
                                                                                    />
                                                                                ) : (
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                        <p
                                                                                            onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                            className='p-2 h-full w-full flex items-center justify-between '>{Intl.NumberFormat('en-US', {
                                                                                                notation: "compact",
                                                                                                maximumFractionDigits: 1
                                                                                            }).format(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0)}
                                                                                            <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "SQTY", postID, fdate, taskID)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                                                                                <LuInfo />
                                                                                            </Button>
                                                                                        </p>
                                                                                        {
                                                                                            isTaskPresent &&
                                                                                            <div
                                                                                                className='absolute bottom-0 right-0 w-1 h-1 triangle-bottomright  transition-all duration-300 ease-in-out cursor-pointer'>
                                                                                            </div>}
                                                                                        {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" && (
                                                                                            <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} qty={'SQTY'} />
                                                                                        )}
                                                                                        {
                                                                                            isCommentPresent &&
                                                                                            <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"FQTY"} postID={postID} fdate={fdate} />
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }

                                                                    // for normal fqty
                                                                    else if ((item["FQTY"] !== null && index > midIndex) || (item["SQTY"] === null && item["FQTY"] === null && index > midIndex)) {
                                                                        return (
                                                                            <td
                                                                                onDoubleClick={() => {
                                                                                    if (checkAllChangesArrayAreEmpty(changes)) {
                                                                                        setChanges((prevState) => [
                                                                                            ...prevState,
                                                                                            createNewEntry(item.FQTY_USER, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                                className={`
                                                                                ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                                 text-left min-w-[110px] max-w-[110px] text-xs px-0 -py-1 ${changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields))
                                                                                        ? 'text-white'
                                                                                        : 'bg-gray-50 text-[#4285F4]'} ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}`}
                                                                            >
                                                                                {changes.some(change => doesChangeMatch(change, details, intervalFiltered[index], additionalFields)) ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            item["FQTY"] !== null
                                                                                                ? item["FQTY"] === item["FQTY_USER"]
                                                                                                    ? parseFloat(Number(item["FQTY"]).toFixed(2)).toString()
                                                                                                    : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString()
                                                                                                : (0)
                                                                                        }
                                                                                        className={`p-2 h-full w-full bg-[#4285F4] ${item["FQTY"] !== null && item["FQTY"] !== item["FQTY_USER"]
                                                                                            ? "text-white bg-[#ed7d31]"
                                                                                            : ""}`}
                                                                                        onChange={(e) => {
                                                                                            e.target.className = `p-2 h-full w-full ${e.target.value !== item["FQTY"] ? "bg-[#ed7d31]" : "bg-[#4285F4]"
                                                                                                }`;
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setChanges((prevState) => prevState.slice(0, -1)); // Remove last entry before updating

                                                                                            setChanges((prevState) => [
                                                                                                ...prevState,
                                                                                                createNewEntry(e.target.value, details, intervalFiltered[index], additionalFields, "FQTY_USER", item['FQTY_USER'])
                                                                                            ]);
                                                                                        }}
                                                                                        autoFocus
                                                                                    />
                                                                                ) :
                                                                                    <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                        <p
                                                                                            onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                            className={`p-2 h-full w-full flex items-center justify-between ${item["FQTY"] !== null ?
                                                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    "" : "text-white bg-[#ed7d31]"
                                                                                                :
                                                                                                ""}`}
                                                                                        >
                                                                                            {Intl.NumberFormat('en-US', {
                                                                                                notation: "compact",
                                                                                                maximumFractionDigits: 1,
                                                                                            }).format(item["FQTY"] !== null ?
                                                                                                (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    parseFloat(Number(item["FQTY"]).toFixed(2)).toString() : parseFloat(Number(item["FQTY_USER"]).toFixed(2)).toString())
                                                                                                :
                                                                                                (0))}
                                                                                            <Button
                                                                                                onClick={() => getSidePanelData(item, trasnposedData, data, index, "FQTY_ENGINE", postID, fdate, taskID)}
                                                                                                type='link'
                                                                                                className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'
                                                                                            >
                                                                                                <LuInfo />

                                                                                            </Button>
                                                                                        </p>
                                                                                        {
                                                                                            isTaskPresent &&
                                                                                            <div
                                                                                                className='absolute bottom-0 right-0 w-1 h-1 triangle-bottomright  transition-all duration-300 ease-in-out cursor-pointer'>
                                                                                            </div>}
                                                                                        {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" && (
                                                                                            <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} qty={'FQTY_ENGINE'} />
                                                                                        )}
                                                                                        {
                                                                                            isCommentPresent &&
                                                                                            <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"FQTY"} postID={postID} fdate={fdate} />
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        );
                                                                    }

                                                                    // for 0 sqty
                                                                    else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                                                                        return (
                                                                            <td className={`
                                                                            ${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-30 scale-110 " : ""} transition-all duration-150 ease-in-out
                                                                            ${(expandAllRows) || ((expandRow || showMultiCellConfigIndex) === rowIndex) ? "bg-violet-100" : "bg-gray-50"}  min-w-[110px] max-w-[110px]`}>
                                                                                <div className={`${contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" ? "z-40 box-shadow" : "z-30"}`}>
                                                                                    <p
                                                                                        onContextMenu={(e) => handleOpenMenu(e, rowIndex, index, item["isSplit"], "FQTY")}
                                                                                        className='p-2 h-full w-full flex items-center justify-between ' >{Intl.NumberFormat('en-US', {
                                                                                            notation: "compact",
                                                                                            maximumFractionDigits: 1
                                                                                        }).format(item["SQTY"] !== null ? parseFloat(Number(item["SQTY"]).toFixed(2)).toString() : 0)}
                                                                                        <Button onClick={() => getSidePanelData(item, trasnposedData, data, index, "SQTY", postID, fdate, taskID)} type='link' className='!h-0 !p-0 !text-slate-400 hover:!text-blue-500'>

                                                                                            <LuInfo />
                                                                                        </Button>
                                                                                    </p>
                                                                                    {
                                                                                            isTaskPresent &&
                                                                                            <div
                                                                                                className='absolute bottom-0 right-0 w-1 h-1 triangle-bottomright  transition-all duration-300 ease-in-out cursor-pointer'>
                                                                                            </div>}
                                                                                    {contextMenu.visible && contextMenu.rowIndex === rowIndex && contextMenu.colIndex === index && contextMenu.qtyType === "FQTY" && (
                                                                                        <CellRightClickMenu data={data} POST_ID={postID} FDATE={fdate} qty={'SQTY'} />
                                                                                    )}
                                                                                    {
                                                                                        isCommentPresent &&
                                                                                        <TopRightCorner rowIndex={rowIndex} colIndex={index} isSplit={item["isSplit"]} qtyType={"FQTY"} postID={postID} fdate={fdate} />
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    }




                                                                })}
                                                            </tr>
                                                            {
                                                                ((expandAllRows) || (expandRow === rowIndex)) &&
                                                                <>

                                                                    {/* actuals */}
                                                                    {
                                                                        Expandchecklist["Actuals"] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Actuals"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }


                                                                    {/* budget */}
                                                                    {
                                                                        Expandchecklist["Budget"] &&

                                                                        <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_BUDGET'} changesArrayName={budgetChanges} setChangesArrayName={setBudgetChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Budget"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                    }

                                                                    {
                                                                        Expandchecklist["Open Orders"] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Open Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist["Quotations"] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Quotations"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist["Year ago"] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Year ago"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist["Forecast (OC)"] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} name={"Forecast (OC)"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Confirmed Orders'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Confirmed Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Due Orders'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Due Orders"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Weather'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Weather"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Price'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Price"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Promotion'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_PROMOTION'} changesArrayName={promotionChanges} setChangesArrayName={setPromotionChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Promotions"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Events'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_EVENT'} changesArrayName={eventChanges} setChangesArrayName={setEventChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Events"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }


                                                                    {
                                                                        Expandchecklist["Sales"] &&
                                                                        <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_SALES'} changesArrayName={salesChanges} setChangesArrayName={setSalesChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Sales Inputs"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                    }


                                                                    {
                                                                        Expandchecklist['Consensus'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_CONSENSUS'} changesArrayName={consensusChanges} setChangesArrayName={setConsensusChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Final Consensus"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Inventory'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Inventory"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist['Marketing'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_MARKET'} changesArrayName={marketChanges} setChangesArrayName={setMarketChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Marketing Inputs"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Discontinued Products'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_DISCONTINUED'} changesArrayName={disChanges} setChangesArrayName={setDisChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"Discontinued Products"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist['New Products'] &&
                                                                        <>
                                                                            <CommonEditableRow rowIndex={rowIndex} checkAllChangesArrayAreEmpty={checkAllChangesArrayAreEmpty} fqty_key_name={'FQTY_NEW'} changesArrayName={newChanges} setChangesArrayName={setNewChanges} doesChangeMatch={doesChangeMatch} createNewEntry={createNewEntry} intervalFiltered={intervalFiltered} details={details} selectedItem={selectedItem} selectedCustomer={selectedCustomer} selectedLocation={selectedLocation} tdateVar={tdateVar} fields={fields} name={"New Products"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />
                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Point of Sales'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} name={"Point of Sales"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }

                                                                    {
                                                                        Expandchecklist['Sell out'] &&
                                                                        <>
                                                                            <CommonExpandedRow rowIndex={rowIndex} tdateVar={tdateVar} fields={fields} name={"Sell out"} additionalFields={additionalFields} trasnposedData={trasnposedData} data={data} midIndex={midIndex} />

                                                                        </>
                                                                    }
                                                                    {
                                                                        Expandchecklist["Opportunities"] &&
                                                                        <>
                                                                            <tr className=''>
                                                                                <td colSpan={1000} className='sticky  p-0 '>
                                                                                    <ProposalTable
                                                                                        trasnposedData={trasnposedData}
                                                                                        tdateVar={tdateVar}
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        midIndex={midIndex}
                                                                                        tdRef={tdRef}
                                                                                        setDetailedProposal={setDetailedProposal}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            {
                                                                                detailedProposal &&
                                                                                <DetailedProposal
                                                                                    setSelectedItem={setSelectedItem}
                                                                                    setSelectedLocation={setSelectedLocation}
                                                                                    setSelectedCustomer={setSelectedCustomer}
                                                                                    fields={fields}
                                                                                    setFields={setFields}
                                                                                    uiMappingData={uiMappingData}
                                                                                    additionalFields={additionalFields}
                                                                                    trasnposedData={trasnposedData}
                                                                                    midIndex={midIndex}
                                                                                    tdRef={tdRef}
                                                                                    tdateVar={tdateVar}
                                                                                    startDate={startDate}
                                                                                    endDate={endDate}
                                                                                    setDetailedProposal={setDetailedProposal}
                                                                                    data={data}
                                                                                    details={details}
                                                                                />
                                                                            }
                                                                        </>
                                                                    }

                                                                    <tr className=''>
                                                                        <td colSpan={2} className='sticky left-0 border-none w-full p-0 z-10'>
                                                                            <ExpandCheckboxList
                                                                                expandAllRows={expandAllRows}
                                                                                setExpandAllRows={setExpandAllRows}
                                                                                Expandchecklist={Expandchecklist}
                                                                                setExpandchecklist={setExpandchecklist} />
                                                                        </td>
                                                                    </tr>

                                                                </>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>)}

                                <div
                                    onClick={() => scrollToOffsetCenter()}
                                    className='fixed bottom-3 hover:bg-blue-500 hover:text-white cursor-pointer h-3  left-[50%] flex text-base px-1 justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                    <div className='rotate-45'>
                                        <PiArrowsInSimple />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    tableRef.current.scrollIntoView({ behavior: 'smooth' });
                                }} className='fixed bottom-4 hover:bg-blue-500 hover:text-white cursor-pointer w-3  right-3 flex text-base  justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                    <div className=''>

                                        <TbArrowNarrowUp />
                                    </div>
                                </div>
                            </div>
                        }
                        {checkBoxOverLay === true ?
                            <Overlay
                                fields={columnsShown.length !== 0 ? columnsShown : [fields["item"], fields["location"], fields["category"]]}
                                locationFilters={locationFilters}
                                productFilters={productFilters}
                                customerFilters={customerFilters}
                                uiMappingData={uiMappingData}
                                setFields={setColumnShown}
                                setNext={setNext}
                                onClose={() => setCheckBoxOverlay(false)} /> : null}
                        {
                            overlayFlag === true ?
                                <EditOverlay

                                    adjLoading={adjLoading}
                                    setAdjLoading={setAdjLoading}
                                    getData={getData}
                                    getGraphData={getGraphData}
                                    changes={changes}
                                    salesChanges={salesChanges}
                                    eventChanges={eventChanges}
                                    marketChanges={marketChanges}
                                    promotionChanges={promotionChanges}
                                    consensusChanges={consensusChanges}
                                    setOverlayFlag={setOverlayFlag}
                                    setChanges={setChanges} /> : null
                        }
                        {
                            inATTFilterPopUp &&
                            <FilterInATTPopUp />
                        }
                        {/* {
                            true &&
                            <DetailedProposal
                                setSelectedItem={setSelectedItem}
                                setSelectedLocation={setSelectedLocation}
                                setSelectedCustomer={setSelectedCustomer}
                                fields={fields}
                                setFields={setFields}
                                uiMappingData={uiMappingData}
                                additionalFields={additionalFields}
                                trasnposedData={trasnposedData}
                                midIndex={midIndex}
                                tdateVar={tdateVar}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        } */}
                        {
                            graphEditPopUp &&
                            <GraphEditPopUp />
                        }

                    </>
            }
            <Tooltip id="my-tooltip" />

        </div>
    )
}

export default PlanningOverview