import { useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import "../styles/Form.css"
import LoadingIndicator from "./LoadingIndicator";
import { Button } from "antd";
import axios from "axios";

function Form({ route, method }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        try {
            const res = await api.post(route, { username, password })
            if (method === "login") {
                // localStorage.setItem("username", username)

                localStorage.setItem(ACCESS_TOKEN, res.data.access);
                localStorage.setItem(REFRESH_TOKEN, res.data.refresh);

                const apiUrl = process.env.REACT_APP_API_URL
                const token = localStorage.getItem("access")

                const response = await fetch(apiUrl + "/api/current_user/", {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const result = await response.json();
                const storedUserID = localStorage.getItem("userid")
                if(result.id !== storedUserID){
                    localStorage.clear();
                }
                localStorage.setItem("org_type", result.org_type)
                localStorage.setItem("username", username)
                localStorage.setItem(ACCESS_TOKEN, res.data.access);
                localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
                localStorage.setItem("userid", result.id)
                localStorage.setItem("ORG_ID", result.org_id);
                localStorage.setItem("showGuide", true)

                
                navigate("/")
            } else {
                navigate("/login")
            }
        } catch (error) {
            // alert(error)
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form-container shadow-md">
            <h1 className="capitalize">{method}</h1>
            <input
                className="form-input"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
            />
            <input
                className="form-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
            />
            {loading && <LoadingIndicator />}
            <button className="form-button capitalize" type="submit">
                {method}
            </button>

            {
                method === "login" ?
                    <div className="flex flex-col justify-center items-center border-t w-full border-t-black gap-2 pt-2">
                        <p>New User ?</p>
                        <Button onClick={() => navigate("/register")} type="primary">Register Here</Button>
                    </div>
                    :
                    <div className="flex flex-col justify-center items-center border-t w-full border-t-black gap-2 pt-2">
                        <p>Account Exists ?</p>
                        <Button onClick={() => navigate("/login")} type="primary">Login Here</Button>
                    </div>
            }
        </form>
    );
}

export default Form