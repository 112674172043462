import React, { useState } from 'react';
import { Input, DatePicker, Select, Button, Tooltip } from 'antd';
import { CalendarOutlined, UserOutlined, FlagOutlined, FileTextOutlined } from '@ant-design/icons';
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';
import { useBase } from '../Layout/baseContext/BaseContext';
import moment from 'moment';
import api from '../../api';

const { TextArea } = Input;
const { Option } = Select;

const AddTaskUI = ({ data, FDATE, qty }) => {
    const { contextMenu, checkTasks } = usePlan();
    const { additionalFields, fields, trasnposedData } = useBase()
    const [taskName, setTaskName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [priority, setPriority] = useState('');
    const [dueDate, setDueDate] = useState(null);

    const [showDescription, setShowDescription] = useState(false);
    const [showDueDate, setShowDueDate] = useState(false);
    const [showAssignedTo, setShowAssignedTo] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [postingTask, setPostingTask] = useState(false)

    // Helper function to get the level based on the rules
    function getLevelAndValue(additionalFields, values, prefix, exactMatch) {
        // Check for exact match (e.g., ITEM, LOCATION, CUSTOMER)
        if (additionalFields.includes(exactMatch)) {
            return [exactMatch, values[additionalFields.indexOf(exactMatch)]];
        }
        // Filter fields starting with the prefix and sort by the number
        const filtered = additionalFields
            .filter(field => field.startsWith(prefix))
            .sort((a, b) => {
                const numA = parseInt(a.match(/\d+/)) || Infinity;
                const numB = parseInt(b.match(/\d+/)) || Infinity;
                return numA - numB;
            });
        // Return the first match if exists, otherwise return null
        if (filtered.length > 0) {
            const field = filtered[0];
            return [field, values[additionalFields.indexOf(field)]];
        }
        return [null, null];
    }

    const handleSubmit = async () => {
        // console.log("----data",data);
        // console.log("----datinputCommenta",inputComment);
        setPostingTask(true)
        // setInputComment(null)
        try {

            const details = data.split('=');
            const fdate = Object.keys(trasnposedData.get(data))[contextMenu.colIndex]
            // // console.log("--res-----",res);

            const generateActivityId = () => {
                return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
            };
            const activityId = generateActivityId();



            let response;
            if (additionalFields.length === 0) {
                response = await api.post("api/createTask/",
                    {
                        "item_level": fields['item'],
                        "location_level": fields['location'],
                        "customer_level": fields['category'],
                        "item_value": details[0],
                        "location_value": details[1],
                        "customer_value": details[2],
                        "task_name": taskName,
                        "task_desc": taskDescription,
                        "priority": priority,
                        "assign_to": assignedTo,
                        "due_date": dueDate,
                        "fdate": fdate,
                        "qty": qty,
                        "username": localStorage.getItem("username"),
                        "activityId": activityId
                    }
                )
            }
            else {
                let item_level = null;
                let location_level = null;
                let customer_level = null;
                let item_value = null;
                let location_value = null;
                let customer_value = null;
                // Populate item_level and item_value
                [item_level, item_value] = getLevelAndValue(additionalFields, details, "I", "ITEM");

                // Populate location_level and location_value
                [location_level, location_value] = getLevelAndValue(additionalFields, details, "L", "LOCATION");

                // Populate customer_level and customer_value
                [customer_level, customer_value] = getLevelAndValue(additionalFields, details, "C", "CUSTOMER");


                console.log({
                    "item_level": item_level,
                    "location_level": location_level,
                    "customer_level": customer_level,
                    "item_value": item_value,
                    "location_value": location_value,
                    "customer_value": customer_value,
                    "task_name": taskName,
                    "task_desc": taskDescription,
                    "priority": priority,
                    "assign_to": assignedTo,
                    "due_date": dueDate ? dueDate.format('YYYY-MM-DD HH:mm:ss') : null,
                    "fdate": fdate,
                    "qty": qty,
                    "username": localStorage.getItem("username"),
                    "activityId": activityId
                });
                response = await api.post("api/createTask/",
                    {
                        "item_level": item_level,
                        "location_level": location_level,
                        "customer_level": customer_level,
                        "item_value": item_value,
                        "location_value": location_value,
                        "customer_value": customer_value,
                        "task_name": taskName,
                        "task_desc": taskDescription,
                        "priority": priority,
                        "assign_to": assignedTo,
                        "due_date": dueDate,
                        "fdate": fdate,
                        "qty": qty,
                        "username": localStorage.getItem("username"),
                        "activityId": activityId

                    }
                )
            }
        }
        catch (e) {
            console.log(3);
        }
        setTaskName('')
        setTaskDescription('')
        setAssignedTo('')
        setDueDate(null)
        setPriority('')
        setPostingTask(false)

        // getCellComments(response.data.POST_ID, FDATE, true)
        checkTasks()
    }

    return (
        <div className='p-1 rounded-md flex flex-col h-full text-xs min-w-56'>

            {/* Task Name */}
            <div className="">
                <Input
                    className='rounded-md m-0 text-xs font-normal w-full !h-6 border-blue-100'
                    placeholder="Enter task"
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                />
            </div>

            {/* Task Description */}
            <div className={`transition-section ${showDescription ? 'show mt-1' : ''}`}>
                <TextArea
                    rows={2}
                    placeholder="Enter task description"
                    className="rounded-md m-0 text-xs font-normal border-blue-100"
                    value={taskDescription}
                    onChange={(e) => setTaskDescription(e.target.value)}
                />
            </div>

            <div className={`flex gap-1 transition-section ${showAssignedTo || showPriority ? 'show mt-1' : ''}`}>
                {/* Assigned To */}
                <div className={`transition-section ${showAssignedTo ? 'show ' : 'hidden'} w-1/2`}>
                    <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        className='rounded-md m-0 small-select text-xs font-normal !h-6 border-blue-100 w-full'
                        placeholder="Assign to"
                        dropdownStyle={{ fontSize: '10px' }}
                        value={assignedTo || undefined}
                        onChange={setAssignedTo}
                    >
                        <Option value={undefined}>None</Option>
                        <Option value="john">John Doe</Option>
                        <Option value="jane">Jane Smith</Option>
                    </Select>
                </div>

                {/* Priority */}
                <div className={`transition-section ${showPriority ? 'show ' : 'hidden'} w-1/2`}>
                    <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        className='rounded-md m-0 small-select text-xs font-normal !h-6 border-blue-100 w-full'
                        placeholder="Priority"
                        dropdownStyle={{ fontSize: '10px' }}
                        value={priority || undefined}
                        onChange={setPriority}

                    >
                        <Option value="low">Low</Option>
                        <Option value="medium">Medium</Option>
                        <Option value="high">High</Option>
                    </Select>
                </div>
            </div>

            {/* Due Date and Time */}
            <div className={`transition-section ${showDueDate ? 'show mt-1' : ''}`}>
                <input
                    type="datetime-local"
                    className=" text-[10px] font-light h-6 px-1 rounded-md border border-blue-200 w-full  focus:outline-none focus:ring-1 focus:ring-blue-400"
                    placeholder="Due date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                />
            </div>

            {/* Submit Button */}
            <div className="flex justify-between mt-2">
                <div className="flex gap-1">
                    <Tooltip title="Add description" placement="bottom">
                        <FileTextOutlined
                            className={`${showDescription ? "bg-blue-100" : ""} cursor-pointer hover:bg-slate-200 rounded-md px-1 text-blue-500 text-xs`}
                            onClick={() => setShowDescription(!showDescription)}
                        />
                    </Tooltip>
                    <Tooltip title="Assign to" placement="bottom">
                        <UserOutlined
                            className={`${showAssignedTo ? "bg-blue-100" : ""} cursor-pointer hover:bg-slate-200 rounded-md px-1 text-blue-500 text-xs`}
                            onClick={() => setShowAssignedTo(!showAssignedTo)}
                        />
                    </Tooltip>
                    <Tooltip title="Set priority" placement="bottom">
                        <FlagOutlined
                            className={`${showPriority ? "bg-blue-100" : ""} cursor-pointer hover:bg-slate-200 rounded-md px-1 text-blue-500 text-xs`}
                            onClick={() => setShowPriority(!showPriority)}
                        />
                    </Tooltip>
                    <Tooltip title="Add due date" placement="bottom">
                        <CalendarOutlined
                            className={`${showDueDate ? "bg-blue-100" : ""} cursor-pointer hover:bg-slate-200 rounded-md px-1 text-blue-500 text-xs`}
                            onClick={() => setShowDueDate(!showDueDate)}
                        />
                    </Tooltip>
                </div>
                <Button loading={postingTask} className='!h-6 !px-2' type='primary' onClick={handleSubmit}>
                    Add Task
                </Button>
            </div>
        </div>
    );
};

export default AddTaskUI;
