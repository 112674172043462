import { message } from 'antd';
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import api from '../../../api';

const BaseContext = createContext();

export const BaseProvider = ({ children }) => {

  // filters
  const [activeItemFilters, setActiveItemFilters] = useState([])
  const [activeLocationFilters, setActiveLocationFilters] = useState([])
  const [activeCustomerFilters, setActiveCustomerFilters] = useState([])
  const [activeDFUFilters, setActiveDFUFilters] = useState([])
  const [activeLFUFilters, setActiveLFUFilters] = useState([])
  const [startingSubSelection, setStartingSubSelection] = useState('')
  const [selectedDFUs, setSelectedDFUs] = useState([]);
  const [selectedLFUs, setSelectedLFUs] = useState([]);

  const [m1LFUdata, setM1LFUdata] = useState([])
  const [m1LFUfilteredData, setM1LFUfilteredData] = useState([])

  //for internal filtering
  const [enabledProductValues, setEnabledProductValues] = useState([]);
  const [enabledLocationValues, setEnabledLocationValues] = useState([]);
  const [enabledCustomerValues, setEnabledCustomerValues] = useState([]);

  //post
  const [selectedPost, setSelectedPost] = useState(null);


  // qty mapping
  const qtyMapping = {
    SQTY: "Sales",
    FQTY: "Actuals",
    FQTY_ENGINE: "Forecast",
    FQTY_BUDGET: "Budget",
    OPEN_OQTY: "Open Orders",
    QQTY: "Quotations",
    FQTY_ENGINE_OC: "Forecast (OC)",
    FQTY_SALES: "Sales",
    FQTY_EVENT: "Events",
    FQTY_PROMOTION: "Promotion",
    FQTY_CONSENSUS: "Consensus",
    FQTY_MARKET: "Marketing",
    FQTY_NEW: "New Products",
    FQTY_DISCONTINUED: "Discontinued Products"
  }



  //supply
  const [supplyTransposedData, setSupplyTransposedData] = useState(new Map());


  const [filterSelectionSeq, setFilterSelectionSeq] = useState({
    "ITEM": 0,
    "LOCATION": 0,
    "CUSTOMER": 0
  })
  const [activeFilters, setActiveFilters] = useState([])
  const [notBlurredFilters, setNotBlurredFilters] = useState({
    "items": [],
    "locations": [],
    "customers": []
  })



  const [selectedMenu, setSelectedMenu] = useState('details')
  const [locationFilter, setLocationFilter] = useState([]);
  const [customerFilter, setCustomerFilter] = useState([]);
  const [productFilter, setProductFilter] = useState([]);
  const [uniqueLFUs, setUniqueLFUs] = useState([])
  const [uniqueDFUs, setUniqueDFUs] = useState([])
  const [fields, setFields] = useState({
    "base": "FQTY_ENGINE",
    "base_2": "SQTY",
    "base_3": "FQTY_BUDGET",
    "base_4": "FQTY_USER",
    "interval": null,
    "item": null,
    "location": null,
    "category": null,
  })
  const previousFields = useRef(fields);
  const previousIntervalRef = useRef(fields['interval']);

  const [additionalFields, setAdditionalFields] = useState([])
  const prevAdditionalFields = useRef([]); // Initialize useRef outside useEffect


  const [filterString, setFilterString] = useState("");
  const [trasnposedData, setTransposedData] = useState(new Map());
  const [intervalList, setIntervalList] = useState([]);

  const [uiMappingData, setUimappingData] = useState(null);
  const [params, setParams] = useState(null);
  const [perfPage, setPerfPage] = useState(null)
  const [graphTypes, setGraphTypes] = useState({
    history: 'bar',
    engine: 'bar',
    planner: 'line',
    budget: 'line',
  });
  const [startDate, setStartDate] = useState(null)
  const [favs, setFavs] = useState([])
  const [canFetchPlan, setcanFetchPlan] = useState(false);
  const [filters, setFilters] = useState(new Map());
  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const [defaultView, setDefaultView] = useState(false)
  const [openSaveFav, setOpenSaveFav] = useState(false)


  const uncheckAll = () => {
    setCheckIndexes([]);
    emptyAll();
    setflag(0)
    setCheckboxCount(-1)
    setSelectedItems([]);
    populateGraphDisplayData();
  }


  //planning
  const [selectedArea, setSelectedArea] = useState("planning");
  const [planningTableLoading, setplanningTableLoading] = useState(false)
  const [checkIndexes, setCheckIndexes] = useState([]);
  const [sqty, setSqty] = useState([]);
  const [fqty, setFqty] = useState([]);
  const [dates, setDates] = useState([]);
  const [fqty_budget, setFqty_budget] = useState([])
  const [fqty_planner, setfqty_planner] = useState([])
  const [flag, setflag] = useState(0)
  const [checkboxCount, setCheckboxCount] = useState(-1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalSqty, setTotalSqty] = useState(null)
  const [totalFqty, setTotalFqty] = useState(null)
  const [graphData, setGraphData] = useState(null);
  const [graphDisplayData1, setGraphDisplayData1] = useState(null);
  const [graphDisplayData2, setGraphDisplayData2] = useState(null);
  const [graphDisplayData3, setGraphDisplayData3] = useState(null);
  const [graphDisplayData4, setGraphDisplayData4] = useState(null);
  const [graphDates, setGraphDates] = useState([]);
  const [endDate, setEndDate] = useState(null)
  const [next, setNext] = useState(true)
  const [page, setPage] = useState(2);
  const [columnsShown, setColumnShown] = useState([])
  const [orgType, setOrgType] = useState('')
  const [delFavLoading, setdelFavLoading] = useState(null)
  const [activeView, setActiveView] = useState([])
  const [saveFavLoading, setSaveFavLoading] = useState(false)
  const [isFetchingPlan, setIsFetchingPlan] = useState(false);
  const [tdateVar, setTdateVar] = useState(null);
  const [adjLoading, setAdjLoading] = useState(false)
  const [fullExpandCheckBoxListArray, setfullExpandCheckBoxListArray] = useState([])
  const [expandCheckBoxListArray, setExpandCheckBoxListArray] = useState([])


  const emptyAll = () => {
    setSqty([])
    setFqty([])
    setDates([])
    setFqty_budget([])
    setfqty_planner([])
  }

  const populateGraphDisplayData = () => {
    var newArray = [];
    var newSecondArray = [];
    var newThirdArray = [];
    var newFourthArray = [];
    var newDateArray = [];

    if (graphData) {

      for (var x = 0; x < graphData.results.length; x++) {
        if (new Date(startDate) <= new Date(graphData.results[x][fields.interval]) && new Date(endDate) >= new Date(graphData.results[x][fields.interval])) {
          newArray.push(graphData.results[x]["SQTY"]);
          newSecondArray.push(graphData.results[x][fields.base]);
          newThirdArray.push(graphData.results[x][fields.base_3]);
          newFourthArray.push(graphData.results[x][fields.base_4])
          newDateArray.push(graphData.results[x][fields.interval]);
        }
      }
    }

    setGraphDisplayData1(newArray);
    setTotalSqty(newArray)
    setGraphDisplayData2(newSecondArray);
    setGraphDisplayData3(newThirdArray);
    setGraphDisplayData4(newFourthArray);
    setTotalFqty(newFourthArray)
    setGraphDates(newDateArray);
  }

  function convertStringToMapAndArray(filterString) {
    const map = new Map();
    let allElements = [];

    // Remove the leading "&" and split by "&" to get each key-value pair
    const keyValuePairs = filterString.slice(1).split('&');

    keyValuePairs.forEach(pair => {
      if (pair) { // Check if the pair is non-empty
        const [key, values] = pair.split('=');

        if (key && values) { // Ensure both key and values exist
          // Split values by commas and decode each value
          const decodedValues = values.split(',').map(value => decodeURIComponent(value));

          // Only set non-empty values in the map
          if (decodedValues.length > 0 && decodedValues.some(val => val !== "")) {
            map.set(key, decodedValues.filter(val => val !== "")); // Filter out empty strings

            // Add the decoded values to the allElements array
            allElements = allElements.concat(decodedValues.filter(val => val !== ""));
          }
        }
      }
    });

    return { map, allElements };
  }


  const setFavView = (view) => {
    if (view.length === 0) {
      setcanFetchPlan(true);
      setExpandCheckBoxListArray(fullExpandCheckBoxListArray)

    }
    else {
      const { map, allElements } = convertStringToMapAndArray(view[0].FILTERSTRING);

      setFields(prev => ({ ...prev, interval: view[0].DURATION }));

      setAdditionalFields(view[0].FIELDS);
      setColumnShown(view[0].FIELDS)
      setFilterString(view[0].FILTERSTRING);
      setFilters(map)
      setCheckBoxArray(allElements)
      setStartDate(view[0].SDATE)
      setEndDate(view[0].EDATE);
      setcanFetchPlan(true)
      if (view[0].EXPANDED_CHECKED_LIST === null || view[0].EXPANDED_CHECKED_LIST.length === 0) {
        setExpandCheckBoxListArray(fullExpandCheckBoxListArray)

      }
      else {

        setExpandCheckBoxListArray(view[0].EXPANDED_CHECKED_LIST)
      }

    }
  }

  const saveFavourite = async (favouriteName) => {
    setSaveFavLoading(true)

    const userid = localStorage.getItem('userid')
    const fieldsArray = additionalFields.length === 0 ? [fields.item, fields.location, fields.category] : additionalFields
    try {
      const result = await api.post('/api/savefav/', {
        USER: userid,
        NAME: favouriteName,
        ISACTIVE: true,
        SDATE: startDate,
        EDATE: endDate,
        FILTERSTRING: filterString,
        FIELDS: fieldsArray,
        DURATION: fields.interval,
        EXPANDED_CHECKED_LIST: expandCheckBoxListArray
      })
      setFavs(result.data.favourites)
      setOpenSaveFav(false)
      localStorage.setItem('favs', JSON.stringify(result.data.favourites));
      message.success("Saved successfully")

    }
    catch (e) {
      message.error("Something went wrong")
    }
    setSaveFavLoading(false)

  }


  const getFavourites = async () => {
    try {
      const userID = localStorage.getItem('userid')
      const response = await api.get(`/api/savefav/`, {
        params: { user_id: userID },
      });
      setFavs(response.data)
      localStorage.setItem('favs', JSON.stringify(response.data));
      const activeItem = response.data.filter((item) => item.ISACTIVE);
      setActiveView(activeItem)
    } catch (error) {
      message.error("Error fetching favourites:", error);
    }
  };

  useEffect(() => {
    const storedFavs = localStorage.getItem('favs');
    const lastFetchDate = localStorage.getItem('lastFetchDateFilters');
    const currentDate = new Date().toISOString().split('T')[0];

    // If data exists in localStorage and the date hasn't changed, use the cached data
    if (storedFavs && lastFetchDate === currentDate) {
      const favViews = JSON.parse(storedFavs)
      const activeItem = favViews.filter((item) => item.ISACTIVE);
      // setActiveView(activeItem)
      setFavView(activeItem)
      setFavs(favViews);
    } else {
      // Date has changed or no data is stored, so fetch new data
      getFavourites();
    }
  }, [])

  useEffect(() => {
    const favData = JSON.parse(localStorage.getItem("favs"))
    if (favs !== null && favs.length === 0 && favData && favData.length === 0) {
      setcanFetchPlan(true)

    }
    else if (activeView && favData) {
      const activeItem = favData.filter((item) => item.ISACTIVE);

      setFavView(activeItem)
    }
  }, [activeView])

  const setNewActiveFav = async (id) => {
    setIsFetchingPlan(false)
    setDefaultView(false)
    const newActiveView = favs.filter((item) => item.id === id)
    const newFav = favs.map(fav =>
      fav.id === id ? { ...fav, ISACTIVE: true } : { ...fav, ISACTIVE: false }
    );
    setFavs(newFav);
    const activeItem = newFav.filter((item) => item.ISACTIVE);
    setActiveView(activeItem)

    localStorage.setItem('favs', JSON.stringify(newFav));

    setFavView(newActiveView)

    try {
      const userID = localStorage.getItem('userid')

      const response = await api.post('/api/setNewfav/', {
        user_id: userID, id: id

      })

    }
    catch (e) {
      message.info("Error while setting this favourite as active")
    }

  }

  const deleteFav = async (item) => {
    setdelFavLoading(item.id)
    setIsFetchingPlan(false)

    try {
      const userID = localStorage.getItem('userid')

      const response = await api.post('/api/delfav/', {
        user_id: userID, id: item.id
      })
      if (item.ISACTIVE === true) {
        window.location.reload();
      }
      const filteredList = favs.filter((fav) => fav.id !== item.id)
      setFavs(filteredList)
      localStorage.setItem('favs', JSON.stringify(filteredList));
    }
    catch (e) {
      message.info("Error while setting this favourite as active")
    }
    setdelFavLoading(null)
  }



  const handleShowDefaultView = () => {
    setIsFetchingPlan(false)
    const storedFields = JSON.parse(localStorage.getItem('fields'));
    setDefaultView(true)
    setFields(prev => ({ ...prev, interval: storedFields.interval }));
    setAdditionalFields([]);
    setColumnShown([])
    setFilterString("");
    setFilters(new Map())
    setStartDate(null)
    setEndDate(null);
    setExpandCheckBoxListArray(fullExpandCheckBoxListArray)
  }


  useEffect(() => {

    if (fullExpandCheckBoxListArray.length !== 0 && favs.length !== 0) {

      const activeItem = favs.filter((item) => item.ISACTIVE);
      if (activeItem[0].EXPANDED_CHECKED_LIST === null || activeItem[0].EXPANDED_CHECKED_LIST === undefined || (activeItem[0].EXPANDED_CHECKED_LIST && activeItem[0].EXPANDED_CHECKED_LIST.length === 0)) {
        setExpandCheckBoxListArray(fullExpandCheckBoxListArray)
      }
      else {

        setExpandCheckBoxListArray(activeItem[0].EXPANDED_CHECKED_LIST)
      }

    }
    else if (fullExpandCheckBoxListArray.length !== 0) {

      setExpandCheckBoxListArray(fullExpandCheckBoxListArray)
    }
  }, [fullExpandCheckBoxListArray, favs])

  const handleRefresh = () => {
    // Get all keys in localStorage
    const keys = Object.keys(localStorage);

    // Iterate over all keys and remove those that are not "access" or "refresh"
    keys.forEach(key => {
      if (key !== "access" && key !== "refresh" && key !== "responses" && key !== "userid" && key !== "username" && key !== "ORG_ID" && key !== "org_type") {
        localStorage.removeItem(key);
      }
    });

    // Reload the page
    window.location.reload();
  };

  useEffect(() => {
    const org_type = localStorage.getItem("org_type");
    if (org_type === 'trial') {
      setSelectedMenu('dataSection')
      setOrgType('trial')
    }
  }, [])



  const createQueryString = (filterArray, details) => {
    // Combine filterArray and details into a query string
    return filterArray
      .map((key, index) => `${key}=${details[index]}`)
      .join("&");
  };

  //sidePanel
  const [planningSidePanelData, setPlanningSidePanelData] = useState(null)
  const [sidePanelLoading, setSidePanelLoading] = useState(false)
  const [clickedQty, setclickedQty] = useState(null)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [cellPostID, setCellPostID] = useState(null)
  const [cellFdate, setCellFdate] = useState(null)
  const [cellData, setCellData] = useState(null)




  const getSidePanelData = async (item, trasnposedData, data, index, qty, postID, fdate, taskID) => {
    setSidePanelLoading(true)
    setShowSidePanel(true);
    setclickedQty(qty)
    setCellPostID(postID)
    setCellFdate(fdate)
    setCellData(data)
    setPlanningSidePanelData(null)
    
    getCellComments(postID, fdate)
    getCellTasks(taskID, fdate,qty)
    const details = data.split("=");
    
    var filterArray = [];
    var queryString = ''
    if (selectedArea === 'post') {
      //for post only
      const filterStringForPostTable = [
        selectedPost.ITEM_LEVEL && selectedPost.ITEM_VALUE ? `&${selectedPost.ITEM_LEVEL}=${selectedPost.ITEM_VALUE}` : '',
        selectedPost.LOCATION_LEVEL && selectedPost.LOCATION_VALUE ? `&${selectedPost.LOCATION_LEVEL}=${selectedPost.LOCATION_VALUE}` : '',
        selectedPost.CUSTOMER_LEVEL && selectedPost.CUSTOMER_VALUE ? `&${selectedPost.CUSTOMER_LEVEL}=${selectedPost.CUSTOMER_VALUE}` : ''
      ].filter(Boolean).join('');
      queryString = `${filterStringForPostTable}&${fields['interval']}=${Object.keys(trasnposedData.get(data))[index]}`
    }
    else {
      if (additionalFields.length === 0) {
        filterArray = [fields['item'], fields['location'], fields['category']]
      }
      else {
        filterArray = [...additionalFields]
      }
      queryString = `&${createQueryString(filterArray, details)}&${fields['interval']}=${Object.keys(trasnposedData.get(data))[index]}`;
    }






    const [itemResponse, locationResponse, customerResponse] = await Promise.all([
      api.get("/api/forecastcubeTable/?fields=ITEM" + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_4"] + "," + fields["base_3"] + "," + "OPEN_OQTY,QQTY,FQTY_SALES,FQTY_CONSENSUS,FQTY_MARKET,FQTY_PROMOTION,FQTY_EVENT,FQTY_NEW,FQTY_DISCONTINUED" + queryString + "&page=1"),
      api.get("/api/forecastcubeTable/?fields=LOCATION" + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_4"] + "," + fields["base_3"] + "," + "OPEN_OQTY,QQTY,FQTY_SALES,FQTY_CONSENSUS,FQTY_MARKET,FQTY_PROMOTION,FQTY_EVENT,FQTY_NEW,FQTY_DISCONTINUED" + queryString + "&page=1"),
      api.get("/api/forecastcubeTable/?fields=CUSTOMER" + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_4"] + "," + fields["base_3"] + "," + "OPEN_OQTY,QQTY,FQTY_SALES,FQTY_CONSENSUS,FQTY_MARKET,FQTY_PROMOTION,FQTY_EVENT,FQTY_NEW,FQTY_DISCONTINUED" + queryString + "&page=1")
    ])



    // Sorting function
    const sortDataByKey = (data, key) => {
      return [...data].sort((a, b) => (b[key] || 0) - (a[key] || 0));
    };

    setPlanningSidePanelData((prev) => ({
      ...prev,
      details: details,
      date: Object.keys(trasnposedData.get(data))[index],
      item: sortDataByKey(itemResponse.data.results, qty),
      location: sortDataByKey(locationResponse.data.results, qty),
      customer: sortDataByKey(customerResponse.data.results, qty)
    }));
    setSidePanelLoading(false)

  }



  const [cellCommentsData, setCellCommentsData] = useState([])
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [postingCommentLoading, setPostingCommentLoading] = useState(false)

  const [cellTaskData, setCellTaskData] = useState([])
  const [taskLoading, setTaskLoading] = useState(false)

  const getCellComments = async (postID, fdate, postingComment) => {
    if (!postingComment) {
      setCommentsLoading(true)
    }
    if (postingComment) {
      setPostingCommentLoading(true)
    }


    try {
      const response = await api.post("api/getComments/", {
        POST_ID: postID,
        FDATE: fdate,
      });

      setCellCommentsData(response.data.comments)
    }
    catch (e) {
      console.log(e);
      setCellCommentsData([])

    }
    setCommentsLoading(false)
    setPostingCommentLoading(false)

  }

  const getCellTasks = async (taskID, fdate, qty) => {
    setTaskLoading(true)


    try {
      const response = await api.post("api/getTasks/", {
        TASK_ID: taskID,
        FDATE: fdate,
        qty:qty,
        username: localStorage.getItem("username")
      });
      console.log("---response----",response);
      setCellTaskData(response.data.tasks)
    }
    catch (e) {
      console.log(e);
      setCellTaskData([])

    }
    setTaskLoading(false)
    // setPostingCommentLoading(false)

  }




  return (
    <BaseContext.Provider value={{
      selectedArea, setSelectedArea,
      selectedMenu, setSelectedMenu,
      locationFilter, setLocationFilter,
      customerFilter, setCustomerFilter,
      productFilter, setProductFilter,
      fields, setFields, previousIntervalRef,
      additionalFields, setAdditionalFields,
      filterString, setFilterString,
      trasnposedData, setTransposedData,
      uiMappingData, setUimappingData,
      perfPage, setPerfPage,
      graphTypes, setGraphTypes,
      params, setParams,
      uncheckAll,
      checkIndexes, setCheckIndexes,
      sqty, setSqty,
      fqty, setFqty,
      dates, setDates,
      fqty_budget, setFqty_budget,
      fqty_planner, setfqty_planner,
      emptyAll,
      flag, setflag,
      checkboxCount, setCheckboxCount,
      selectedItems, setSelectedItems,
      totalSqty, setTotalSqty,
      totalFqty, setTotalFqty,
      populateGraphDisplayData,
      graphData, setGraphData,
      graphDisplayData1, setGraphDisplayData1,
      graphDisplayData2, setGraphDisplayData2,
      graphDisplayData3, setGraphDisplayData3,
      graphDisplayData4, setGraphDisplayData4,
      planningTableLoading, setplanningTableLoading,
      graphDates, setGraphDates,
      startDate, setStartDate,
      endDate, setEndDate,
      columnsShown, setColumnShown,
      next, setNext,
      page, setPage,
      saveFavourite,
      favs,
      canFetchPlan, setcanFetchPlan,
      filters, setFilters,
      handleShowDefaultView,
      checkBoxArray, setCheckBoxArray,
      defaultView, setDefaultView,
      setNewActiveFav,
      openSaveFav, setOpenSaveFav,
      handleRefresh,
      supplyTransposedData, setSupplyTransposedData,
      orgType,
      deleteFav, delFavLoading,
      saveFavLoading,
      previousFields, prevAdditionalFields,
      isFetchingPlan, setIsFetchingPlan,
      notBlurredFilters, setNotBlurredFilters,
      selectedDFUs, setSelectedDFUs,
      uniqueLFUs, setUniqueLFUs,
      uniqueDFUs, setUniqueDFUs,
      filterSelectionSeq, setFilterSelectionSeq,
      activeFilters, setActiveFilters,
      selectedLFUs, setSelectedLFUs,
      tdateVar, setTdateVar,
      intervalList, setIntervalList,
      adjLoading, setAdjLoading,
      showSidePanel, setShowSidePanel,
      getSidePanelData,
      planningSidePanelData,
      sidePanelLoading,
      clickedQty,
      qtyMapping,
      selectedPost, setSelectedPost,
      activeItemFilters, setActiveItemFilters,
      activeLocationFilters, setActiveLocationFilters,
      activeCustomerFilters, setActiveCustomerFilters,
      activeDFUFilters, setActiveDFUFilters,
      activeLFUFilters, setActiveLFUFilters,
      expandCheckBoxListArray, setExpandCheckBoxListArray,
      fullExpandCheckBoxListArray, setfullExpandCheckBoxListArray,
      enabledProductValues, setEnabledProductValues,
      enabledLocationValues, setEnabledLocationValues,
      enabledCustomerValues, setEnabledCustomerValues,
      m1LFUdata, setM1LFUdata,
      m1LFUfilteredData, setM1LFUfilteredData,
      startingSubSelection, setStartingSubSelection,
      cellCommentsData, setCellCommentsData,
      commentsLoading,
      postingCommentLoading,
      setPostingCommentLoading,
      getCellComments,
      cellPostID,
      cellFdate,
      cellData,
      getCellTasks,
      cellTaskData, setCellTaskData,
      taskLoading, setTaskLoading
    }}>
      {children}
    </BaseContext.Provider>
  );
};

export const useBase = () => useContext(BaseContext);