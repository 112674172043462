import React, { useState } from 'react'
import api from '../../api';
import { TailSpin } from 'react-loader-spinner';
import { Button, Input } from 'antd';
import { TbSend2 } from 'react-icons/tb';
import { useBase } from '../Layout/baseContext/BaseContext';
import moment from 'moment';
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';

const CommentsUI = ({ data, FDATE }) => {
    const { fields, additionalFields, trasnposedData, getCellComments, cellCommentsData, commentsLoading, postingCommentLoading, setPostingCommentLoading,showSidePanel } = useBase();
    const { contextMenu,checkComments } = usePlan()
    const [inputComment, setInputComment] = useState(null)


    // Helper function to get the level based on the rules
    function getLevelAndValue(additionalFields, values, prefix, exactMatch) {
        // Check for exact match (e.g., ITEM, LOCATION, CUSTOMER)
        if (additionalFields.includes(exactMatch)) {
            return [exactMatch, values[additionalFields.indexOf(exactMatch)]];
        }
        // Filter fields starting with the prefix and sort by the number
        const filtered = additionalFields
            .filter(field => field.startsWith(prefix))
            .sort((a, b) => {
                const numA = parseInt(a.match(/\d+/)) || Infinity;
                const numB = parseInt(b.match(/\d+/)) || Infinity;
                return numA - numB;
            });
        // Return the first match if exists, otherwise return null
        if (filtered.length > 0) {
            const field = filtered[0];
            return [field, values[additionalFields.indexOf(field)]];
        }
        return [null, null];
    }

    const handleSubmit = async () => {
        // console.log("----data",data);
        // console.log("----datinputCommenta",inputComment);
        setPostingCommentLoading(true)
        setInputComment(null)
        const details = data.split('=');

        const fdate = Object.keys(trasnposedData.get(data))[contextMenu.colIndex]
        // console.log("--res-----",res);



        const generateActivityId = () => {
            return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
        };
        const activityId = generateActivityId();
        let response;
        if (additionalFields.length === 0) {
            response = await api.post("api/postComment/",
                {
                    "item_level": fields['item'],
                    "location_level": fields['location'],
                    "customer_level": fields['category'],
                    "item_value": details[0],
                    "location_value": details[1],
                    "customer_value": details[2],
                    "comment_text": inputComment,
                    "fdate": fdate,
                    "username": localStorage.getItem("username"),
                    "activityId": activityId
                }
            )
        }
        else {
            let item_level = null;
            let location_level = null;
            let customer_level = null;
            let item_value = null;
            let location_value = null;
            let customer_value = null;
            // Populate item_level and item_value
            [item_level, item_value] = getLevelAndValue(additionalFields, details, "I", "ITEM");

            // Populate location_level and location_value
            [location_level, location_value] = getLevelAndValue(additionalFields, details, "L", "LOCATION");

            // Populate customer_level and customer_value
            [customer_level, customer_value] = getLevelAndValue(additionalFields, details, "C", "CUSTOMER");



            response = await api.post("api/postComment/",
                {
                    "item_level": item_level,
                    "location_level": location_level,
                    "customer_level": customer_level,
                    "item_value": item_value,
                    "location_value": location_value,
                    "customer_value": customer_value,
                    "comment_text": inputComment,
                    "fdate": fdate,
                    "username": localStorage.getItem("username"),
                    "activityId": activityId
                }
            )
        }
        getCellComments(response.data.POST_ID, FDATE, true)
        checkComments()
    }


    const username = localStorage.getItem("username")

    return (
        <div className='p-1  rounded-md flex flex-col gap-1 h-full  min-w-56'>
            {
                commentsLoading ?
                    <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex justify-center items-center`}><TailSpin
                        visible={true}
                        height="20"
                        width="20"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>
                    :
                    <div className='w-full h-full'>
                        {
                            cellCommentsData.length === 0 ?
                                <p className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex text-slate-500 text rounded-md justify-center items-center`}>No comments yet</p>
                                :
                                <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"}  relative h-full w-full`}>
                                    <div className='absolute h-full w-full'>


                                        <div className={`  h-full rounded-md flex flex-col gap-1 pb-1 overflow-y-scroll scroll w-full`}>


                                            {
                                                cellCommentsData.map((comment, index) => (

                                                    <>
                                                        {
                                                            username && (username === comment.BY_USER) ?
                                                                <div className='w-full flex flex-col items-end'>

                                                                    <div className='flex flex-col items-end bg-white rounded-md px-2 pb-1  w-fit'>
                                                                        <p className='text-[10px] leading-3  text-blue-300 mt-1'>{comment.BY_USER}</p>
                                                                        <p className='text-[10px]  leading-[0.85rem] text-bodyTextBlue text-wrap'>{comment.COMMENT}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='w-full flex flex-col items-start'>
                                                                    <div className='flex flex-col  bg-white rounded-md px-2 pb-1  w-fit'>
                                                                        <p className='text-[10px] leading-3  text-blue-300 mt-1'>{comment.BY_USER}</p>
                                                                        <p className='text-[10px]  leading-[0.85rem] text-bodyTextBlue text-wrap'>{comment.COMMENT}</p>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
            }
            <div className='flex gap-1'>
                <Input
                    name="comment"
                    placeholder="Write comment"
                    onChange={(e) => setInputComment(e.target.value)}
                    value={inputComment}
                    className='rounded-md m-0 bg-white text-xs font-normal !h-6 border-blue-100'
                />
                <Button loading={postingCommentLoading} onClick={() => handleSubmit()} className='!h-6 !p-1' type='primary'>
                    {
                        postingCommentLoading ? ""
                            :
                            <TbSend2 />
                    }
                </Button>
            </div>
        </div>
    )
}

export default CommentsUI