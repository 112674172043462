import React, { useState } from 'react'
import { useBase } from '../../Layout/baseContext/BaseContext'
import { TailSpin } from 'react-loader-spinner';
import { Checkbox } from 'antd';
import { LuInfo } from "react-icons/lu";
import api from '../../../api';


const TaskListUI = () => {
    const { cellTaskData, setCellTaskData,
        taskLoading, setTaskLoading, showSidePanel } = useBase()

    const [info, setInfo] = useState(null)

    console.log("-cellTaskData-----------", cellTaskData);
    const extractDateTime = (datetimeString) => {
        const dateObj = new Date(datetimeString);

        // Extract date in YYYY-MM-DD format
        const datePart = dateObj.toISOString().split('T')[0];

        // Extract hour and minute in HH:MM format
        const hours = String(dateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const timePart = `${hours}:${minutes}`;

        return { date: datePart, time: timePart };
    };

    const updateStatus = async (task_detail_id, is_completed) => {
        try {
            const result = api.post("api/taskStatus/",{
                TASK_DETAIL_ID:task_detail_id,
                IS_COMPLETED:is_completed
            })
            console.log("---------rsult",result);
        }
        catch (e) {
            console.log(e);
        }
    }
    return (
        <div className='w-full h-full flex flex-col'>
            <h3 className='text-sm font-semibold px-1 pb-1 flex items-center'>
                Task list
            </h3>
            <div className='h-full w-full'>
                {
                    taskLoading ?
                        <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex justify-center items-center`}><TailSpin
                            visible={true}
                            height="20"
                            width="20"
                            color="#2B3674"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /></div>
                        :
                        <div className='w-full h-full flex flex-col'>
                            {
                                cellTaskData && cellTaskData.length === 0 ?
                                    <p className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"} flex text-slate-500 text rounded-md justify-center items-center pb-7`}>No tasks</p>
                                    :
                                    <div className={`${showSidePanel ? "h-full" : "max-h-16 min-h-16"}  relative h-full w-full`}>
                                        <div className='absolute h-full w-full'>


                                            <div className={`  h-full rounded-md flex flex-col gap-1 pb-1 overflow-y-scroll scroll w-full`}>



                                                {cellTaskData.map((item) => (
                                                    <div className='flex justify-between items-center w-full border-b pb-1'>

                                                        <Checkbox
                                                            key={item.TASK_DETAIL_ID}
                                                            className=''
                                                            onChange={(e) => updateStatus(item.TASK_DETAIL_ID, e.target.checked)}
                                                            defaultChecked={item.IS_COMPLETED}
                                                        >
                                                            {item.TASK_NAME}
                                                            {
                                                                item.DUE_DATE_TIME &&
                                                                <div className='text-red-500 pr-1 text-[10px] leading-[0.5rem]'>{extractDateTime(item.DUE_DATE_TIME).date} {extractDateTime(item.DUE_DATE_TIME).time}</div>
                                                            }
                                                        </Checkbox>
                                                        <div onClick={() => setInfo(item.TASK_DETAIL_ID)} className='pr-2 text-slate-500 text-base hover:text-blue-500 cursor-pointer'>
                                                            <LuInfo />
                                                        </div>
                                                        {/* {
                                                            info === item.TASK_DETAIL_ID &&
                                                            <div className='absolute h-fit top-0 right-10 bg-white'>
                                                               
                                                                <p>Description: {item.TASK_DESC}</p>

                                                                <p>Assigned to: {item.TASK_ASSIGNED_TO}</p>
                                                            </div>
                                                        } */}
                                                    </div>
                                                ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default TaskListUI